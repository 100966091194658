$facebook-primary: #1777f2;
$facebook-link: #385898;

$twitter-primary: #1da1f2;
$twitter-username: #8899a6;
$twitter-info: #8899a6;
$twitter-link: #2b7bb9;
$twitter-link-hover: #3b94d9;

$instagram-link: #0094f6;
$instagram-comment-input: #8e8e8e;

$deere-green: #367C2B;
$deere-yellow: #ffde00;
$casece-orange: #dc8c37;
$caseih-red: #a51b25;
$kubota-orange: #f15a22;
$massey-ferguson-red: #f00;
$new-holland-ag-blue: #004a90;

$showroom-colors: (
    'deere': $deere-green,
    'casece': $casece-orange,
    'caseih': $caseih-red,
    'kubota': $kubota-orange,
    'massey_ferguson': $massey-ferguson-red,
    'new_holland_ag': $new-holland-ag-blue,
    'new_holland_construction': #000,
    'vermeer': #000
);

$font-family-condensed: 'Roboto Condensed', sans-serif;

// add Bootstrap overrides before including the Bootstrap variables
$primary: $deere-green;
$secondary: $deere-yellow;

$headings-font-family: $font-family-condensed;
$font-family-sans-serif: Roboto, sans-serif;
$headings-font-weight: 700;
$font-weight-semi-bold: 500;
$font-size-base: 0.9375rem; //15px
$font-size-xs: 0.8125rem; //13px
$font-size-sm: 0.875rem; //14px
$font-size-md: 1.0625rem; //17px
$font-size-xl: 1.875rem; //30px
$input-font-size: 1rem; // prevents zooming on iOS
$btn-disabled-opacity: 0.35;

$h1-font-size: $font-size-base * 3; //45px
$h2-font-size: $font-size-base * 2.5333; //38px
$h3-font-size: $font-size-base * 2; //30px
$h4-font-size: $font-size-base * 1.4667; //22px
$h5-font-size: $font-size-base * 1.1333; //17px
$h6-font-size: $font-size-base;

// max number of columns per row to support with the .row-[x]-col classes.
$grid-row-columns: 6;

$box-shadow: 3px 3px 9px -3px rgba(0, 0, 0, 0.4);

$gray-100: #f9f9f9; // light theme cards bg, etc.
$gray-200: #f0f0f0; // light theme nav bars, etc.
$gray-300: #e3e3e3; // light theme menu item borders, etc.
$gray-400: #adadad; // lighter dark theme nav text color, etc.
$gray-500: #808080; // dark theme nav text color, etc.
$gray-600: #6c6c6c; // light theme nav text color, etc.
$gray-700: #474747; // dark theme nav lighlight, etc.
$gray-800: #393939; // dark theme nav bars, etc.
$gray-900: #1c1919; // dark theme body color, middle of nav, nav borders, etc.

$dark: $gray-900;
$input-border-color: $gray-300;
$input-color: $gray-600;
$input-placeholder-color: $gray-500;

$element-brand-colors: primary, secondary, warning;

// Add additional theme color helpers (.btn-accent-1, .bg-accent-1, etc.)
// Bootstrap will extend this variable with it's default theme colors (primary, secondary, etc.)
$theme-colors: (
    'accent-0': #fff,
    'accent-1': $gray-100,
    'accent-2': $gray-200,
    'accent-3': $gray-300,
    'accent-4': $gray-400,
    'accent-5': $gray-500,
    'accent-6': $gray-600
);

// Main and hover backgrounds for vertical navs in the dark theme
$vertical-nav-dark-tier1-link-bg: $gray-800;
$vertical-nav-dark-tier1-link-hover-bg: darken($gray-800, 7%);
$vertical-nav-dark-tier2-link-bg: $gray-600;
$vertical-nav-dark-tier2-link-hover-bg: darken($gray-600, 7%);

$breadcrumb-divider: quote('>');
$card-grid-border-width: 1px;
$modal-right-pane-width: 85vw;

$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1400px
);
