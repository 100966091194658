﻿.location-store-detail {
    .card-img-top {
        width: 100%;
        height: 230px;
        object-fit: cover;
        object-position: top;
    }

    .rounded {
        border-radius: 0.25rem;
    }

    .map {
        z-index: 1;
        width: 100%;
        height: 500px;
        background-color: grey;
        border: 15px solid white;
    }

    .theme-dark & {
        background: none !important;

        .location-info {
            background-color: $gray-800;
        }

        .location-open-closed {
            color: #fff !important;
        }

        .card {
            background-color: $gray-800;
            border-radius: 0;
            box-shadow: none;
        }
    }

    .employee-email {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}
