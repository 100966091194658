﻿// slanted edge options
.edge--bottom {
    @include angle(after);
}

.edge--bottom--reverse {
    @include angle(after, true);
}

.edge--top {
    @include angle(before);
}

.edge--top--reverse {
    @include angle(before, true);
}

.edge--both {
    @include angle(both);
}

.edge--both--reverse {
    @include angle(both, true);
}

//Colors are temporary

.-orange {
    background: #f9a31f;
}

.-green {
    background: #3f8831;
}

.-red {
    background: #c01826;
}

.-darkgrey {
    background: #858585;
}
.stacked ol {
    padding: 0;
    li a {
        font-size: 14px;
        color: white;
    }
}
