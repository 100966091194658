// utility classes for use on text

.font-weight-semi-bold {
    font-weight: $font-weight-semi-bold !important;
}

.font-weight-md-normal {
    @include media-breakpoint-up(md) {
        font-weight: $font-weight-normal !important;
    }
}

.font-width-condensed {
    font-family: $font-family-condensed !important;
}

.font-size-xs {
    font-size: $font-size-xs !important;
}

.font-size-sm {
    font-size: $font-size-sm !important;
}

.font-size-lg {
    font-size: $font-size-lg !important;
}
