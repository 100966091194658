// creates border sizing utility classes: border-1, border-2, etc.
@for $i from 1 through 5 {
    .border-#{$i} {
        border-width: #{$i}px !important;
    }
}

// adds theme support for Bootstrap .border-primary and .border-secondary utility classes
@each $color in $element-brand-colors {
    .border-#{$color} {
        border-color: var(--#{$color}) !important;
    }
}

// use to place a vertical line to the left or right of content
.border-content {
    @extend .border-primary, .border-3;

    &.border-left {
        @extend .pl-3;
    }

    &.border-right {
        @extend .pr-3;
    }
}
