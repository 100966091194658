﻿#new-featured-equipment {
    background-color: rgba(0, 0, 0, 0);
    height: 370px;
    position: relative;
    z-index: 2; // pushes overlapping images over other content

    // sm instead of xs since BS4 doesn't provide an xs-min breakpoint
    // TODO: test that this works ok
    @include media-breakpoint-up(sm) {
        height: 500px;
    }

    // TODO: verify going up from sm to md breakpoint works ok
    @include media-breakpoint-only(md) {
        height: 450px;
    }

    // NOTES: left this one as lg-min so there isn't a breakpoint range without height applied
    @include media-breakpoint-up(lg) {
        height: 563px;
    }

    .logo-wrapper {
        display: block;
        text-align: center;
        margin-top: 10px;
        z-index: 1;
        cursor: pointer;

        // TODO verify bumping 'ss' to 'sm' works since BS4 doesn't provide 'ss'
        @include media-breakpoint-up(sm) {
            width: 27%;
        }

        // TODO verify bumping 'sm' to 'md' works
        @include media-breakpoint-up(md) {
            display: block;
            margin-top: 20px;
            width: auto;
            text-align: center;
            top: 0;
        }

        // TODO verify bumping 'md' to 'lg' works
        @include media-breakpoint-up(lg) {
            width: 27%;
            margin-top: 9px;
        }

        // TODO verify bumping 'lg' to 'xl' works
        @include media-breakpoint-up(xl) {
            width: 27%;
            margin-top: 9px;
        }
    }

    .logo {
        width: 50%;

        @include media-breakpoint-up(md) {
            width: auto;
            max-height: 75px;
        }
    }

    .equipment {
        max-height: 600px;
        width: 100%;
        position: relative;

        // TODO verify bumping 'ss' to 'sm' works (BS4 doesn't provide 'ss'
        @include media-breakpoint-up(sm) {
            position: absolute;
            top: -9%;
        }

        // TODO verify bumping 'sm' to 'md' works
        @include media-breakpoint-up(md) {
            top: 0;
            position: relative;
            margin-top: 0;
        }

        // TODO verify bumping 'sm' to 'md' works
        @include media-breakpoint-only(md) {
            width: 150%;
            left: -25%;
        }

        // TODO verify bumping 'md' to 'lg' works
        @include media-breakpoint-up(lg) {
            width: auto;
            position: absolute;
            left: -37%;
            max-width: 770px;
        }

        // TODO verify bumping 'lg' to 'xl' works
        @include media-breakpoint-up(xl) {
            left: -200px;
            max-width: 2000px;
            top: -15%;
        }
    }

    .showroom-link {
        // TODO: use a variable here if this isn't a heading (h4, etc.)
        // or create a "font-variant-condensed" utility
        // consider using utility classes for other styles
        // font-family: Roboto Condensed;
        text-align: center;
        text-decoration: underline;
        color: #1a1a1a;
    }

    .card-wrapper {
        position: relative;
        margin-top: 40px;
        margin-bottom: 20px;
        padding: 25px 20px 20px 20px;
        border-top: 2px solid;
        border-bottom: 2px solid;
        max-width: 370px;
        left: 0;

        @media (min-width: 576px) and (max-width: 767px) {
            display: none;
        }

        // TODO does this need to be bumped to 'md'?
        @include media-breakpoint-up(sm) {
            max-width: 465px;
        }

        // TODO does this need to be bumped to 'lg'?
        @include media-breakpoint-up(md) {
            display: block;
        }

        // TODO does this need to be bumped to 'xl'?
        @include media-breakpoint-up(lg) {
            left: 75px;
        }
    }
}
