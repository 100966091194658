﻿$breakpoint-lg: 1199px;
$breakpoint-md: 991px;
$breakpoint-sm: 768px;
$max-width-sm: 768px;

.hero-img-bg {
    position: relative;
    background-size: cover;
    opacity: 0.85;
}

.hero-content {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 1;
}

.cta-container {
    max-width: 500px;
}

.hero-cta:hover {
    color: #fff;
}

.cta-container .hero-cta {
    width: 46%;
    padding: 12px;
    text-align: center;
    font-weight: 600;
    border-radius: 15px;
    display: inline-block;
}

@media (max-width: $breakpoint-md) {
    .hero-img-bg.desktop {
        width: 100%;
        height: 0;
        padding-top: 44%;
    }

    .hero-content {
        margin-top: 8%;
        margin-left: 8%;
        margin-bottom: 8%;
        max-width: 80%;
    }

    .h-third {
        width: 33.33%;
        height: 0;
        padding-top: 20%;
        float: left;
    }
}

@media (min-width: $breakpoint-md) {
    .hero-img-bg.desktop {
        width: 80%;
        height: 0;
        padding-top: 40%;
        float: left;
    }

    .hero-content {
        margin-top: 8%;
        margin-left: 8%;
        margin-bottom: 8%;
        opacity: 1;
    }

    .h-third {
        width: 100%;
        height: 0;
        padding-top: 66.67%;
    }
}

@media (max-width: $breakpoint-sm) {
    .hero-img-bg.desktop {
        width: 100%;
        height: 0;
        padding-top: 70%;
    }

    .hero-content {
        margin-top: 8%;
        margin-left: 8%;
        margin-bottom: 8%;
        max-width: 90%;
    }

    .h-third {
        width: 33.33%;
        height: 0;
        padding-top: 20%;
        float: left;
    }
}
