﻿$skeleton-bg: #ccc;
$skeleton-highlight-bg: lighten($skeleton-bg, 10%);

@keyframes skeleton-progress {
    0% {
        transform: translate3d(-100%, 0, 0);
    }
    25%,
    100% {
        transform: translate3d(100%, 0, 0);
    }
}

.skeleton {
    &.skeleton-inline {
        display: flex;
        justify-content: space-between;

        .skeleton-item {
            margin-left: 0.5rem;

            &:first-child {
                margin-left: 0;
            }
        }
    }

    .skeleton-item,
    .skeleton-title {
        background: $skeleton-bg;
        height: 1rem;
        margin-bottom: .5rem;
        overflow: hidden;
        position: relative;
        width: 100%;

        &::after {
            animation: skeleton-progress 1.5s ease-in-out infinite;
            background: linear-gradient(90deg, $skeleton-bg, $skeleton-highlight-bg, $skeleton-bg);
            content: '';
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    .skeleton-title {
        height: 2.8rem;
        margin-bottom: 1rem;
        width: 75%;
    }
}
