.promotions {
    div:empty:not([class^='skeleton-']) {
        display: none;
    }

    #sort-by-dropdown {
        font-size: 14px;
        height: calc(1.5em + 0.75rem + 7px);
    }

    .multiselect__tags {
        min-height: unset;
    }

    .multiselect__placeholder {
        font-weight: bold;
    }

    .page-item {
        margin-bottom: 0 !important;
    }
}

.promotion-detail {
    div:empty:not([class^='skeleton-']) {
        display: none;
    }

    .sharing-icons {
        font-size: 1.2rem;
    }

    .search-results {
        width: 100%;
    }

    #search-input::placeholder {
        font-size: 1.05rem;
    }
}

.promotion-locations {
    .map {
        border: 1rem solid #fff;
        min-height: 250px;

        .gm-style-iw-d {
            max-height: unset !important;
        }
    }
}
