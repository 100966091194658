﻿.saved-equipment {
    font-size: 0.9rem;

    .saved-eq-button {
        z-index: 1;
    }

    .saved-eq-button,
    .compare-button {
        font-weight: $font-weight-semi-bold;
    }

    &.rounded .saved-eq-button,
    &.rounded .compare-button {
        background-color: #ddd;
        font-weight: $font-weight-bold;
        height: 85%;

        .badge {
            padding: 0.4rem 0.4rem .3rem;
        }
    }

    button {
        font-size: 0.9rem; // Overrides the default button font size
    }
}

.saved-badge:before {
    border: solid transparent;
    content: ' ';
    top: 50%;
    left: -10px;
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-left-color: $gray-100;
    border-width: 15px;
    margin-top: -15px;
}

@include media-breakpoint-up(md) {
    // TODO: Use kebab case
    .arrow_box:after,
    .arrow_box:before {
        left: 75%;
    }

    // TODO: Use kebab case
    .arrow_box:before {
        border-width: 10px;
        margin-top: -10px;
    }

    // TODO: Use kebab case
    .savedEQ {
        margin-left: 2rem !important;
    }
}

@media (max-width: 425px) {
    .arrow_box:after,
    .arrow_box:before {
        left: 75%;
    }

    // TODO: Use kebab case
    .savedEQ {
        margin-left: 1rem !important;
    }
}

@media (max-width: 375px) {
    // TODO: Use kebab case
    .arrow_box:after,
    .arrow_box:before {
        left: 72%;
    }
}

@media (max-width: 320px) {
    .savedEQ {
        margin-left: 0 !important;
    }

    .arrow_box:after,
    .arrow_box:before {
        left: 68%;
    }
}

button.icon.savedEQ.arrow_box {
    padding: 13px 10px;
}

.comparing-shadow {
    box-shadow: 0 11px 15px 0 rgba(0, 0, 0, 0.13);
    position: relative;
    z-index: 2;
}

.comparing {
    max-width: 505px;

    span {
        color: #ffde00;
    }
}

.equipment-box {
    background-color: #fff;
    border-radius: 3px;
    // TODO: can one of the available BS4 shadow variables be used here?
    box-shadow: 0px 3px 12px -3px rgba(97, 95, 97, 0.75); // $card-shadow
    max-width: 500px;
    width: 100%;
}

.saved-eq {
    background-color: #f9f9f9;
    // TODO: can one of the available BS4 shadow variables be used here?
    box-shadow: 0px 3px 15px 3px rgba(97, 95, 97, 0.5); // custom
    cursor: auto;
    height: 100vh;
    position: fixed;
    right: 0;
    top: 0;
    transform: translate(0);
    transition: transform 0.5s;
    width: 95%;
    z-index: 5;

    // TODO: verify this breakpoint works
    @include media-breakpoint-up(sm) {
        width: auto;
    }

    h5 {
        text-align: center;
    }
}

.saved-equipment-list {
    max-height: calc(100% - 145px);
    overflow-y: auto;

    // TODO: verify these breakpoints work
    @include media-breakpoint-up(sm) {
        max-height: calc(100% - 65px);
    }

    @include media-breakpoint-up(md) {
        max-height: calc(100% - 145px);
    }
}

.saved-heading {
    min-width: 395px;

    h4 {
        font-size: 1.3rem;

        @include media-breakpoint-up(sm) {
            font-size: 1.5rem;
        }
    }
}

.saved-out {
    height: 100vh;
    position: fixed;
    transform: translate(650px);
    transition: 0.5s ease-out;
    right: 0;
    top: 0;
    z-index: 4;
}
