﻿// TODO complete refactoring
.new-equipment-showroom {
    text-align: center;

    .showroom-bg-color-primary {
        color: #fff !important;

        ::placeholder {
            color: #fff !important;
        }
    }

    a.showroom-bg-color-primary:hover,
    a .card-footer.showroom-bg-color-primary:hover,
    .btn.showroom-bg-color-primary:hover,
    .desktop-nav-link-container .router-link-exact-active:hover,
    .desktop-nav-link-container .router-link-exact-active .fas,
    #special-form-wrapper .sf-SubmitButton,
    #special-form-wrapper .sf-SubmitButton:hover {
        color: #fff !important;
    }

    // deere, case ih, and massey ferguson need white behind their logos
    .deere,
    .caseih,
    .massey_ferguson {
        .brand-logo a img {
            background-color: #fff;
        }
    }

    // apply vendor colors to showroom
    @each $color, $value in $showroom-colors {
        .#{$color} {

            .showroom-color-primary {
                color: $value;
            }

            // kill primary text color if it's the same as it's background
            .showroom-bg-color-primary > .showroom-color-primary {
                color: inherit;
            }

            .breadcrumbs .router-link-active:hover,
            .desktop-nav-link-container a:not(.router-link-active):hover,
            .showroom-bg-color-primary > .triangle-right.showroom-color-primary {
                color: $value !important;
            }

            // apply primary vendor color to backgrounds
            .showroom-bg-color-primary,
            .category a.router-link-active,
            .desktop-nav-link-container .router-link-exact-active,
            .desktop-nav-link-container .router-link-exact-active:hover,
            #special-form-wrapper .sf-SubmitButton {
                background-color: $value !important;
            }

            // make all hovered vendor background colors 7% darker
            .category a:hover,
            a.showroom-bg-color-primary:hover,
            a .card-footer.showroom-bg-color-primary:hover,
            .btn.showroom-bg-color-primary:hover,
            #special-form-wrapper .sf-SubmitButton:hover {
                background-color: darken($value, 7%) !important;
            }
        }
    }

    .deere {
        .showroom-color-secondary {
            color: $deere-yellow;
        }
    }

    .new_holland_ag {
        .category-link {
            img {
                display: none;
            }
        }
    }

    .container {
        display: flex;
        flex-direction: row;
        height: auto;

        @media (max-width: 767px) {
            max-width: 100%;
            padding: 0;
        }

        @media (min-width: 768px) {
            height: 700px;
        }

        @media (min-width: 992px) {
            height: 800px;
        }

        @media (min-width: 1024px) {
            height: 800px;
        }
    }

    .mobile-filter {
        background-color: $gray-300;

        ul {
            list-style-type: none;
        }

        .input-group-text {
            background-color: #fff;
            border: none;
        }
    }

    .mobile-navigation {
        list-style: none;
        overflow-y: scroll;
        padding: 0;
        margin: 0;

        .categories {
            list-style: none;
            padding: 0;
            margin: 0;
        }

        .categories > li > a {
            color: inherit;
            width: 100%;
            font-size: 22px;
            display: block;
            padding: 2px;
            border: 1px solid $gray-200;
        }

        .categories > li > a.router-link-active {
            border: none;
        }

        .subcategories {
            display: none;
            list-style: none;
            padding: 0;
            margin: 0;

            a {
                color: inherit;
                font-size: 17px;
            }
        }

        .router-link-active + .subcategories {
            display: block;
        }
    }

    .btn.back-to-top {
        position: fixed;
        bottom: 20px;
        right: 20px;
        z-index: 3;
        font-size: 26px;
        height: 60px;
        width: 60px;
        border-radius: 50%;
        border: none;
        box-shadow: 0px 1px 8px 1px #222;
    }

    .sidebar {
        flex: 2 1 20%;
        display: flex;
        flex-direction: column;
        min-width: 200px;
        z-index: 2;
        overflow-x: hidden;
        position: relative;
    }

    .brand-logo {
        flex: 1 1 25%;
        position: relative;

        img {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 100%;
            padding: 10px 10px;
        }
    }

    .category-list {
        flex: 4 4 75%;
        list-style: none;
        text-align: left;
        overflow-y: scroll;
        margin-right: -18px;
        background-color: #fff;
    }

    .categories {
        list-style: none;
        padding: 0;
        text-transform: uppercase;
    }

    .category a {
        padding: 7px 15px;
        display: block;
        font-size: 14px;
        cursor: pointer;
        font-weight: 700;
        color: $gray-500;

        &:hover,
        &.router-link-active {
            color: #fff;
        }
    }

    .router-link-active + .subcategories {
        margin-top: 2px;
        display: block;
        list-style: none;
    }

    .subcategories {
        display: none;
        padding: 0;
        list-style: none;

        a.subcategory {
            display: block;
            padding: 7px 25px;
            font-size: 13px;
            cursor: pointer;
            font-weight: 600;
        }
    }

    .showroom {
        display: flex;
        flex-direction: column;
        flex: 4 3 80%;
        max-width: 100%;
        overflow-y: scroll;

        @media (min-width: 768px) {
            overflow-y: auto;
        }
    }

    .category-title {
        font-size: 22px;
        font-weight: 500;
        padding: 10px;
        margin: 0;

        a {
            color: #fff;
        }
    }

    .logo-wrapper {
        max-width: 100%;
        max-height: 100%;
    }

    .modal {
        text-align: left;
        color: black;
    }

    .nav-wrapper {
        width: 100%;
        flex-wrap: wrap;
        padding: 1rem;
        justify-content: center;
    }

    .category-link {
        flex: 0 1 100%;
        background-color: #fff;
        border-radius: 0.25rem;
    }

    .category-image {
        max-width: 100%;
        border-radius: 0.25rem;
    }

    .hero-wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 800px; // safari fix
        height: 100%;
    }

    .hero-text {
        font-size: 14px;

        h1 {
            @media (max-width: 425px) {
                font-size: 1.5rem;
                padding: 4px;
            }
        }

        h2 {
            @media (max-width: 425px) {
                font-size: 1.1rem;
                padding: 3px;
            }
        }

        h3 {
            @media (max-width: 425px) {
                font-size: 1rem;
                padding: 2px;
            }
        }

        padding: 10%;
        text-align: left;
    }

    .new-eq-showroom-hero {
        flex: 1 1 auto;
        background-size: cover;
        background-position: center;
    }

    .featured-item-wrapper {
        flex: 1 1 auto;
        display: flex;
        flex-direction: row;
    }

    .featured-item {
        position: relative;
        flex: 1 1 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .featured-item-text {
        padding: 10%;

        ul {
            text-align: left;
        }
    }

    .featured-item-image {
        flex: 1 1 50%;
        background-size: cover;
        background-position: center;
    }

    .triangle-right {
        z-index: 1;
        position: absolute;
        right: -30px;
        height: 0;
        width: 0;
        border-top: 20px solid transparent;
        border-bottom: 20px solid transparent;
        border-left: 30px solid;
        margin-top: -15px;
        top: 50%;
    }

    .title-bar {
        text-align: left;
        display: flex;
        flex-direction: row;
        margin: 0;
        box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.5);
        z-index: 2;

        label {
            font-weight: 600;
        }

        .sort {
            @media (min-width: 768px) {
                padding: 0px 2px 0 24px;
                border: none;
            }

            @media (min-width: 1024px) {
                padding: 12px 2px 0 15px;
                border-left: 2px solid #f0f0f0;
                border-right: 2px solid #f0f0f0;
            }

            select {
                vertical-align: middle;
            }
        }

        .show {
            @media (max-width: 425px) {
                ul {
                    padding: 0 !important;
                }

                .grid-icon {
                    font-size: 1.5em;
                }
            }

            @media (min-width: 768px) {
                padding: 0px 2px 0 24px;
            }

            @media (min-width: 1024px) {
                padding: 12px 2px 0 15px;
            }

            font-size: 15px;

            ul {
                display: inline-block;
                text-align: left;
                list-style: none;
                padding-left: 5px;
                margin-bottom: 0;

                li {
                    display: inline-block;
                }
            }
        }
    }

    .breadcrumbs {
        display: inline-block;
        text-align: left;
        list-style: none;
        padding: 10px 20px 10px 20px;
        margin-bottom: 0;

        li {
            display: inline-block;

            a {
                padding: 0px 5px 0px 5px;
                display: block;
                color: #000;
                font-size: 14px;

                @media (min-width: 768px) {
                    font-size: 16px;
                }

                @media (min-width: 1024px) {
                    font-size: 20px;
                }
            }
        }
    }

    .grid-wrapper {
        height: 800px;
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .grid {
        flex: 1 1 80%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: flex-start;
        overflow-y: scroll;
        padding: 15px;
        margin-left: 3px;

        @media (min-width: 768px) {
            padding: 0;
        }
    }

    .grid-cell,
    .grid-cell-lg {
        margin-right: 4px;
        margin-bottom: 15px;
        z-index: 2;

        @media (min-width: 768px) {
            margin-bottom: 4px;
        }
    }

    .grid-cell {
        flex: 1 1 50%;
        max-width: calc(50% - 5px);
        height: 125px;

        @media (min-width: 768px) {
            flex: 1 1 24%;
            max-width: calc(25% - 5px);
            height: 250px;
            height: calc(33.3% - 2px);
        }
    }

    .grid-cell-lg {
        flex: 1 1 50%;
        max-width: calc(100% - 5px);
        height: 250px;

        @media (min-width: 768px) {
            flex: 1 1 32%;
            max-width: calc(33.33% - 5px);
            height: 400px;
            height: calc(50% - 2px);
        }
    }

    @media (max-width: 767px) {
        .grid-cell:nth-child(2n + 2) {
            margin-right: 0;
        }
    }

    @media (min-width: 768px) {

        .grid-cell:nth-child(4n + 4),
        .grid-cell-lg:nth-child(3n + 3) {
            margin-right: 0;
        }
    }

    .grid-cell .machine-item-body {
        flex: 1 1 80%;
        display: flex;
        flex-direction: column;
        padding-left: 5px;
        padding-right: 5px;

        h5 {
            flex: 0 0 auto;
            font-size: 15px;
        }

        h5.compress {
            font-size: 10px;
        }

        .machine-image {
            flex: 10 1 auto;
            position: relative;

            img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                max-width: 100%;
                max-height: 100%;
            }
        }

        @media (min-width: 1024px) {
            h5 {
                font-size: 18px;
            }

            h5.compress {
                font-size: 13px;
            }
        }
    }

    .grid-cell-lg .machine-item-body {
        flex: 1 1 80%;
        display: flex;
        flex-direction: column;
        padding-left: 5px;
        padding-right: 5px;

        .machine-image {
            flex: 10 0 auto;
            position: relative;

            img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                max-width: 100%;
                max-height: 100%;
            }
        }

        @media (min-width: 768px) {
            h5 {
                flex: 1 1 auto;
                font-size: 15px;
            }

            h5.compress {
                font-size: 12px;
            }
        }

        @media (min-width: 1024px) {
            h5 {
                font-size: 18px;
            }

            h5.compress {
                font-size: 15px;
            }
        }
    }

    .grid-cell-lg .call-to-action a {
        flex: 1 1 50%;
        padding: 5px;

        @media (min-width: 1024px) {
            padding: 10px;
        }
    }

    .grid-cell .call-to-action a {
        flex: 1 1 50%;
        padding: 2px;
    }

    .machine-list-item {
        position: relative;
        display: flex;
        flex-direction: column;

        .call-to-action {
            flex: 0 0 auto;
            display: flex;
            flex-direction: row;
            border-top: 1px solid $gray-200;
            bottom: 0;

            :hover {
                cursor: pointer;
                background-color: rgba(0, 0, 0, 0.05);
                color: $gray-500;
            }
        }
    }

    .loading-spinner {
        position: absolute;
        top: 60%;
        left: 30%;
        overflow: hidden;

        @media (min-width: 768px) {
            top: 40%;
            left: calc(50% + 25px);
        }

        .fa-spinner {
            color: $gray-500;
            font-size: 125px;
            margin-top: 125px;

            @media (min-width: 768px) {
                font-size: 200px;
                margin-top: 200px;
            }
        }
    }

    .filter-bar {
        flex: 0 1 35px;
        text-align: left;
        padding-left: 15px;
        font-weight: 600;
        padding-top: 4px;

        div {
            width: 100%;
        }

        .fa-search {
            margin-left: -25px;
        }

        select {
            width: calc(100% - 25px);
        }

        input {
            width: calc(100% - 25px);
            background-color: transparent;
            border: 0;
            border-bottom: 2px solid black;
            margin-bottom: 4px;
        }

        @media (min-width: 768px) {

            input,
            select {
                width: calc(100% - 10px);
            }

            display: inline-flex;
            padding-left: 26px;
            padding-right: 26px;
        }
    }

    .theme-dark & {
        .new-equipment-showroom-wrapper {
            background-color: $gray-900;
        }

        .category a {
            color: #fff;
        }

        .category-list {
            background-color: $vertical-nav-dark-tier1-link-bg;
        }

        .category a:hover,
        .category a.router-link-active {
            background-color: $vertical-nav-dark-tier1-link-hover-bg !important;
        }

        .subcategories {
            background-color: $vertical-nav-dark-tier2-link-bg;
        }

        .subcategories li a:hover,
        .subcategories a.router-link-active {
            background-color: $vertical-nav-dark-tier2-link-hover-bg !important;
        }

        .breadcrumbs li a {
            color: #fff;
        }

        .grid {
            background-color: unset;
        }

        .machine-list-item {
            background-color: $gray-800;
            margin-bottom: .3rem;

            a,
            a:hover {
                color: #fff;
            }
        }

        .filter-bar input {
            color: #fff;
        }
    }
}
