﻿.cursor-pointer {
    cursor: pointer !important;
}

.cursor-not-allowed {
    cursor: not-allowed !important;
}

.cursor-unset {
    cursor: unset !important;
}

.pe-none {
    pointer-events: none !important;
}

.white-space-pre-line {
    white-space: pre-line !important;
}

@keyframes glowing {
    0% {
        box-shadow: 0 0 -10px var(--secondary);
    }
    40% {
        box-shadow: 0 0 5px 4px var(--secondary);
    }
    60% {
        box-shadow: 0 0 5px 4px var(--secondary);
    }
    100% {
        box-shadow: 0 0 -10px var(--secondary);
    }
}

.glow {
    animation: glowing 1500ms infinite;
}
