// use to make a form control small on larger devices only
.form-control-md-sm {
    @include media-breakpoint-up(md) {
        height: $input-height-sm;
        padding: $input-padding-y-sm $input-padding-x-sm;
        @include font-size($input-font-size-sm);
        line-height: $input-line-height-sm;
        @include border-radius($input-border-radius-sm);
    }
}
