﻿.event-featured {
    bottom: 3rem;
    margin-bottom: -3rem;
}

.event-upcoming {
    border-bottom: 0.125rem solid;
    font-style: italic;
    font-weight: bold;
    text-transform: uppercase;
}

.event-big-img-container {
    background-color: rgba(0, 0, 0, 0.03);
    border: 10px solid white;
    box-shadow: $box-shadow;
    height: 200px;
    margin-bottom: 1.5rem;
}

.theme-dark {
    .event-big-img-container {
        background-color: $gray-800;
        border: 10px solid $gray-300;
        box-shadow: none;
    }

    .event-title {
        background-color: $gray-800;
    }

    .card.disclaimer {
        background-color: $gray-800;
        box-shadow: none;
    }
}

.event-content {
    max-height: 16rem;
    max-width: 100%;
    overflow: auto;
}

// Used in blogs too
.featured-img-box {
    padding: 0.25rem;
    width: 100%;
    z-index: 1;
    min-height: 200px;

    figcaption {
        background-color: var(--primary);
        bottom: 0;
        color: #fff;
        left: 0;
        opacity: 0.7;
        padding: 0.65rem;
        position: absolute;
        right: 0;

        &.featured-border {
            bottom: 0.6rem;
            border-right: 10px solid #fff;
            border-left: 10px solid #fff;
        }
    }
}

.events-card {
    background-color: $gray-100;
    box-shadow: $box-shadow;
    height: auto;

    .theme-dark & {
        background-color: $gray-800;
        box-shadow: none;

        .card-title,
        .card-footer {
            color: #fff !important;
        }

        .card-footer {
            background-color: $gray-900;
            border: 2px solid $gray-800;
        }
    }

    .events-card-container {
        height: 200px;
        margin-bottom: 0 !important;
        padding: 1rem;

        img {
            // Fix streched image issue in IE
            flex-shrink: 0;
        }
    }
}

/*http://cssdeck.com/labs/calendar-icon*/
time.icon {
    background-color: $white;
    box-shadow: 3px 3px 9px -3px #615f61;
    display: block;
    font-size: 1em;
    /* change icon size */
    height: 5em;
    overflow: hidden;
    position: relative;
    transform-origin: 50% 10%;
}

time.icon * {
    display: block;
    font-size: 1em;
    font-weight: bold;
    font-style: normal;
    text-align: center;
    width: 100%;
}

time.icon strong {
    font-size: 0.8em;
    position: absolute;
    top: 0;
    padding: 0.2em 0.2em;
    color: #fff;
    background-color: var(--primary);
    box-shadow: 0 2px 0 var(--primary);
}

time.icon em {
    position: absolute;
    bottom: 0.2em;
    color: var(--primary);
}

time.icon span {
    width: 100%;
    font-size: 1.5em;
    letter-spacing: -0.05em;
    padding-top: 1.025em;
    color: #2f2f2f;
}

@include media-breakpoint-up(sm) {
    .event-big-img-container,
    .featured-img-box {
        min-height: 280px;
    }
}

@include media-breakpoint-up(md) {
    .event-featured {
        bottom: 4rem;
        margin-bottom: -4rem;
    }

    .featured-img-box {
        width: 60%;
    }
}

@include media-breakpoint-up(lg) {
    .event-featured {
        bottom: 6rem;
        margin-bottom: -6rem;
    }

    .event-big-img-container,
    .featured-img-box {
        min-height: 400px;
        width: 70%;
    }

    .event-big-img-container {
        margin-bottom: 0;
    }
}
