// gives the no-ui-slider component a Material UI look and feel and applies branding colors

.noUi-horizontal {
    margin-top: 5px;
    z-index: 0 !important;

    .noUi-handle {
        border-radius: 50%;
        box-shadow: none;
        border: none;
        background-color: var(--primary);
        transition: all 0.2s;
        cursor: pointer;
        outline: none;

        &::before,
        &::after {
            content: none;
        }

        &.noUi-active {
            transform: scale(1.25);
        }
    }

    &[disabled] {
        opacity: var(--btn-disabled-opacity);
    }

    &.noUi-target {
        background: #ccc;
        border: none;
        border-radius: 0;
        box-shadow: none;
    }

    .noUi-connect {
        background-color: var(--primary);
    }

    &.noUi-horizontal {
        height: 2px;

        .noUi-handle {
            width: 16px;
            height: 16px;
            top: -7px;
            right: -8px !important;
        }
    }
}
