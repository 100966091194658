﻿.new-equipment-showroom-detail {
    display: flex;
    flex-direction: column;
    height: auto;

    @media (min-width: 768px) {
        height: 100%;
        overflow-y: hidden;
    }

    h1 {
        font-size: 1.5rem;
    }

    .equipment-detail-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex: 1 0 50%;
        position: relative;
        background-size: cover;
        background-position: center;

        .equipment-detail-title {
            font-weight: 400;
            padding: 0 50px;
            max-width: 100%;

            @media (min-width: 768px) {
                font-size: 22px;
            }

            @media (min-width: 1024px) {
                font-size: 24px;
            }

            @media (min-width: 1200px) {
                font-size: 26px;
            }
        }

        .equipment-detail-back {
            position: absolute;
            top: 0;
            left: 0;
            box-shadow: 0px 2px 3px $gray-700;
            border-radius: 3px;

            &:hover {
                background-color: rgba(#000, 0.05) !important;
            }
        }

        @media (min-width: 768px) {
            flex-direction: row;
            align-items: center;
        }
    }

    .equipment-detail-image {
        max-width: 100%;
        max-height: 100%;
        margin: auto;
    }

    #machine-nav-carousel {
        .splide__arrow {
            top: 20%;
        }

        a {
            color: inherit;

            &.router-link-active {
                font-weight: 600;
            }
        }
    }

    .equipment-image-wrapper {
        flex: 1 1 50%;
        max-height: 90%;
    }

    .detail-section {
        flex: 1 1 50%;
        width: 100%;
    }

    .desktop-nav {
        flex: 0 0 120px;

        .desktop-nav-link-container {
            display: flex;
            flex-direction: row;
            z-index: 2;

            a {
                flex: 1 0 20px;
                flex: 1 0 20px;

                @media (min-width: 768px) {
                    padding: 20px 12px;
                    font-size: 15px;
                }

                @media (min-width: 1024px) {
                    padding: 20px 15px;
                    font-size: 18px;
                }

                border-right: 1px solid;
                box-shadow: 0px -1px 2px rgba(0, 0, 0, 0.5);

                .fas,
                .fab {
                    font-size: 30px;
                }
            }

            a:hover {
                background-color: #ddd !important;
            }

            svg {
                fill: currentColor;
                height: 30px;
                width: 30px;
            }

            .router-link-exact-active {
                margin-top: -20px;
                padding-top: 30px;
            }
        }
    }

    .machine-specs-img {
        overflow-y: auto;
        overflow-x: hidden;
        max-height: 95%;
        padding-bottom: 10px;
        max-width: 100%;
    }

    .machine-features,
    .machine-overview,
    .machine-specs,
    .specials,
    #special-form-wrapper {
        padding-bottom: 10px;
        max-width: 100%;
        padding-right: 8px;
        min-height: 400px;

        @media (min-width: 768px) {
            overflow-y: auto;
            overflow-x: hidden;
            max-height: 95%;
        }

        ul.slides {
            list-style: none;
            margin-left: 0;
        }

        td {
            padding: 2px;
        }
    }

    #special-form-wrapper {
        height: auto;
    }

    .machine-features,
    .machine-overview,
    .machine-specs {
        a,
        img,
        .page-type {
            display: none;
        }
    }

    .machine-detail-banner {
        display: flex;
        flex-direction: row;
        box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.5);
        margin-bottom: 2px;

        .machine-image {
            flex: 1 1 50%;

            img {
                max-width: 100%;
                max-height: 100%;
            }
        }
    }

    .machine-cta {
        flex: 1 1 50%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        .cta-button {
            font-weight: 500;
            flex: 1 1 calc(50% - 16px);
            color: inherit;
            padding: 8px;
            font-size: 18px;
            border-radius: 4px;
            box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);
            cursor: pointer;
            width: calc(50% - 16px);
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .cta-button:hover {
            background-color: rgba(0, 0, 0, 0.1);
        }
    }

    .brochure {
        box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);
        white-space: normal;
    }

    .btn.brochure {
        color: #808080 !important;
    }

    .btn.brochure:hover {
        background-color: rgba(0, 0, 0, 0.1);
    }

    .video-container {
        display: flex;
        flex-direction: column;
        height: 100%;

        .video-wrapper {
            flex: 1 0 100%;
            width: 90%;
            margin: auto;

            .video-player {
                width: 100%;
                height: 72%;
            }
        }
    }

    .machine-detail-content {
        flex: 1 0 50%;
        flex-direction: row;
        flex-wrap: wrap;
        max-width: 100%;

        @media (min-width: 768px) {
            max-height: 450px;
            overflow: hidden;
            height: 90%;
        }

        @media (min-width: 1024px) {
            max-height: 600px;
        }

        h1 {
            flex: 0 1 100%;
            font-size: 1.5rem;
        }

        h2 {
            font-size: 1.4rem;
        }

        h3 {
            font-size: 1.35rem;
        }

        h4 {
            flex: 0 1 100%;
            font-size: 1.3rem;
            border-bottom: 1px solid black;
            margin-top: 10px;
        }

        dd,
        dt {
            flex: 1 1 50%;
        }

        .summary,
        .description,
        disclaimer {
            h4 {
                flex: 0 1 100%;
                font-size: 1.3rem;

                .underline {
                    text-decoration: none;
                }
            }

            ul {
                list-style: none;
                margin-left: 0;
                padding: 0;
            }
        }

        .link {
            cursor: pointer;
            font-weight: 600;
        }

        .specials-image-container {
            flex: 1;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
        }

        .specials-card {
            box-shadow: 2px 2px 7px 0 rgba(0, 0, 0, 0.5);
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            height: 290px;

            @include media-breakpoint-up(md) {
                height: 360px;
            }

            @include media-breakpoint-up(lg) {
                height: 390px;
            }

            @include media-breakpoint-up(xl) {
                height: 490px;
            }
        }
    }
}
