﻿body {
    overflow-x: hidden;
}

.centered {
    display: flex;
    align-items: center;
    justify-content: center;
}

.card-image-wide {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.default-card-text {
    max-width: 90%;
}

@include media-breakpoint-up(md) {
    .default-card-text {
        max-width: 75%;
    }
}

.order-1 {
    .cardContentText {
        display: none !important;
        visibility: hidden;
    }
}

.order-2 {
    .cardContentText {
        display: none;
    }
}

// TODO: use kebab-case
.noImage {
    hr {
        width: 25%;
        background-color: black;
    }

    // TODO: use kebab-case
    .cardContent {
        display: none;
    }

    // TODO: use kebab-case
    .cardImage {
        display: none;
    }
}

.jumbotron {
    background-color: inherit;
    margin: 0;
}

// TODO: use kebab-case
.jumbotroncardContentText {
    h3 {
        font-size: 1.125rem;
    }

    p {
        font-size: 1rem;
    }
}

//Backgrounds
// TODO: use .bg-transparent instead
.noBackground {
    background-image: none;
}

// TODO: use kebab-case and move to utility
.textureBackground {
    background-repeat: no-repeat;
    background-image: url(../assets/img/bak.jpg);
    background-size: cover;

    .theme-dark & {
        background-image: none;
    }
}

// TODO: use .bg-white instead
.whiteBackground {
    background-color: white;
}

// TODO: use .bg-light instead
.greyBackground {
    background-color: #f4f4f7;
}

.eq-default-card {
    display: none;
    z-index: 1;
    max-width: 125%;
    position: absolute;
    bottom: -50px;
    left: -20%;
}

@include media-breakpoint-up(md) {
    .eq-default-card {
        display: block;
        position: unset;
        margin: 0 auto;
    }
}

@include media-breakpoint-up(lg) {
    .eq-default-card {
        padding-right: 100px;
    }
}

.eq-large {
    z-index: 1;
    max-width: 100%;
    position: relative;
    top: -5%;
    width: 100%;
}

@include media-breakpoint-up(sm) {
    .eq-large {
        left: 10%;
        height: 291px;
        width: 523px;
    }
}

@include media-breakpoint-up(lg) {
    .eq-large {
        left: 0;
        width: 790px;
        height: 410px;
    }
}

// TODO: what's this used for? Just use a border utility if still in use.
@include media-breakpoint-up(sm) {
    .rowTest > div:not(:last-child) {
        border-right: 1px solid #ccc;
    }
}

.hr-top {
    display: flex;
    padding-bottom: 15px;

    .hr-line {
        background-color: var(--primary);
        width: 100%;
        position: relative;
        margin: 15px 0;
        border: 1px solid rgba(0, 0, 0, 0.1);
    }

    .hr-icon {
        position: relative;
        font-size: 20px;
        top: 3px;
        color: #ccc;
    }
}

/*@media screen and (max-width: 1023px) {
    .eq, .eq-large {
        position: static;
        max-width: 100%;
    }
    .jumbotron {
        text-align: center;
    }
}*/
