﻿@font-face {
    font-family: 'BrandArrows';
    src: url('../assets/SocialStream/fonts/icomoon.eot?ccn4ds');
    src: url('../assets/SocialStream/fonts/icomoon.eot?ccn4ds#iefix') format('embedded-opentype'),
        url('../assets/SocialStream/fonts/icomoon.ttf?ccn4ds') format('truetype'),
        url('../assets/SocialStream/fonts/icomoon.woff?ccn4ds') format('woff'),
        url('../assets/SocialStream/fonts/icomoon.svg?ccn4ds#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"]:before,
[class^="icon-"]:after,
[class*=" icon-"]:before,
[class*=" icon-"]:after {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'BrandArrows' !important;
    font-size: 5px;
    vertical-align: middle;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-left-arrow:before {
    content: "\e901";
}

.icon-right-arrow:after {
    content: "\e900";
}
