﻿.used-equipment-gallery {
    .splide__slide {
        text-align: center;

        // image area is fixed to 200px tall on smaller devices
        // setting line height to the same height allows vertically centering images that are less than 200px high within the slide.
        line-height: 200px;

        img {
            max-height: 100%; // prevents large ratio images from being stretched/cropped.
            cursor: pointer; // helps give the user feedback that clicking the slider image will bring up the lightbox.
            vertical-align: middle; // vertically center low ratio images.
        }

        @include media-breakpoint-up(sm) {
            line-height: 400px; // images on larger devices are fixed to 400px tall
        }
    }
}

// remove padding designated for pagination since pagination is hidden on devices <= 992px
.used-equipment-gallery,
.similar-equipment-slider {
    &.splide {
        @include media-breakpoint-down(md) {
            padding-bottom: 0;
        }
    }
}
