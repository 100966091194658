body {
    min-height: 100vh;
}

#app {
    flex: 1;

    //Fixes an issue where SF and Bootstrap rows outside of containers don't play well together.
    & > .row {
        margin-left: 0;
        margin-right: 0;

        & > .col-md-12 {
            padding-left: 0;
            padding-right: 0;
        }
    }
}

// Forms
input[type='email'],
input[type='search'],
textarea {
    outline: none;
    box-shadow: none;
}

input::placeholder {
    font-size: $input-font-size-sm;
}

.form-control:focus {
    border-color: #ced4da;
}

// For iframes injected by plugins https://stackoverflow.com/a/6735357
iframe[src='javascript:false'] {
    display: block;
}

// Vue animations
.fade-enter-active,
.fade-leave-active,
.fade-desktop-enter-active,
.fade-desktop-leave-active,
.drawer-enter-active,
.drawer-leave-active {
    transition: opacity 0.3s ease-in-out;
}

.fade-enter-to,
.fade-leave,
.fade-desktop-enter-to,
.fade-desktop-leave,
.drawer-enter-to,
.drawer-leave {
    opacity: 1;
}

.fade-enter,
.fade-leave-to,
.fade-desktop-enter,
.fade-desktop-leave-to,
.drawer-enter,
.drawer-leave-to {
    opacity: 0;
}
