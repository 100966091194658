//https://w3bits.com/css-grid-masonry/

.masonry {
    display: grid;
    grid-template-columns: repeat(1, minmax(100px, 1fr));
    grid-gap: 10px;
    grid-auto-rows: 0;
}

@media only screen and (max-width: 1023px) and (min-width: 768px) {
    .masonry {
        grid-template-columns: repeat(2, minmax(100px, 1fr));
    }
}

@media only screen and (min-width: 1024px) {
    .masonry {
        grid-template-columns: repeat(3, minmax(100px, 1fr));
    }
}

.masonry-item,
.masonry-content {
    width: 100%;
    border-radius: 4px;
    overflow: hidden;
}

.masonry-item {
    box-shadow: 0px 2px 2px rgba(0, 0, 0, .3);
    transition: filter .25s ease-in-out;
}

.masonry-footer {
    font-size: .75em;
    opacity: .25;
    text-align: center;
    padding-top: 3em;
    padding-bottom: 3em;
    margin-bottom: -1.5em;
    transition: opacity 1s ease-in-out;
}