﻿.search-bar {
    .suggestions-bar {
        position: relative;
        z-index: 2;
    }

    .suggestions-bar-results {
        position: absolute;
        border: 1px solid #eee;
        transition: all 0.5s ease-in-out;
    }

    .suggestions-bar-results > .row:first-child {
        min-height: 400px;
    }

    .all-results-btn > span {
        color: var(--secondary);
    }
}

.search-bar-header {
    .suggestions-bar-results {
        min-width: 900px;
        right: 0;
        width: 50vw;
    }

    .search .fa-search {
        position: absolute;
        left: 92%;
        font-size: 14px;
        top: 20px;
        color: #a8a8a8;
    }

    .fa-search {
        font-size: 17px;
    }
}

.search-bar-hero-cards {
    .suggestions-bar {
        border-bottom: 2px solid #333;
        padding: 0;
    }

    .suggestions-bar-results {
        width: 100%;
    }

    .search {
        input {
            background: none;
            border: none;
            font-size: 1.15rem;
            font-style: italic;
            font-weight: bold;
            height: auto;
            vertical-align: middle;
            width: 100%;

            &:focus,
            &:hover {
                box-shadow: none;
            }
        }

        ::placeholder {
            font-size: 1.15rem;
        }
    }
}

.search-bar-hero-with-search {
    .search input {
        height: 65px;
        text-align: center;
    }
}
