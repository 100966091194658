.location-list-dropdown {
    .location-menu {
        position: absolute;
        top: 2.45rem;
        left: 0px;
        z-index: 4;
    }

    .dropdown-item {
        border-bottom: 1px gray solid;

        .theme-dark & {
            color: white;
        }
    }
}
