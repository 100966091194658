// Arrow SVG size.
$splide-arrow-size: 2.5em !default;

// Pagination indicator width.
$splide-indicator-width: 20px !default;

// Pagination indicator height.
$splide-indicator-height: 5px !default;

// Height of a progress bar.
$splide-progress-bar-height: 3px !default;
