a[class^='share-network-'] {
    align-items: center;
    border-radius: 3px;
    color: #fff !important;
    cursor: pointer;
    display: inline-flex;
    margin-bottom: 10px;
}

a[class^='share-network-'] .fab,
a[class^='share-network-'] .fas {
    background-color: rgba(0, 0, 0, 0.2);
    min-width: 40px;
    padding: 10px;
    text-align: center;
}

a[class^='share-network-'] span {
    font-weight: 500;
    padding: 0 10px;
}

a.share-network-use-default-background {
    &.share-network-facebook {
        background-color: $facebook-primary;
    }

    &.share-network-twitter {
        background-color: $twitter-primary;
    }
}
