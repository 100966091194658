#header-rounded {
    .location-list-dropdown button {
        height: 85%;
    }

    .menu-bricks li {
        line-height: 1.2;
        z-index: 3;
    }
}

#header-mobile-rounded {
    .fa-bars {
        font-size: 2rem;
    }

    .search-bar input::-webkit-search-cancel-button {
        padding-right: 1rem;
    }
}

#header-rounded,
#header-mobile-rounded {
    .search-bar {
        input {
            background-color: #ddd;
            border: 1px solid $gray-400;
            border-radius: 30px;
            min-height: 50px;
            padding-left: 2.3rem;
        }

        .suggestions-bar {
            z-index: 4;
        }

        .suggestions-bar:before {
            content: '\f002';
            display: inline-block;
            font-family: 'Font Awesome 5 Free';
            font-style: normal;
            font-weight: 900;
            left: 1rem;
            position: absolute;
            top: 30%;

            .theme-dark & {
                color: $gray-800;
            }
        }
    }

    .menu-bricks-right {
        display: inline-flex;
        font-weight: $font-weight-semi-bold;
        list-style: none;
        margin: 0;
        padding-left: 0;

        li {
            background-color: #ddd;
            border-radius: 0.25rem;
            display: flex;
            height: 85%;
            margin: auto;
            margin-left: 0.25rem;
            padding: 0 0.75rem;

            a {
                margin: auto;
                padding: 0;
            }

            &:hover {
                background-color: #cdcdcd;
            }
        }

        @include media-breakpoint-down(lg) {
            font-size: 0.9em;
        }
    }
}
