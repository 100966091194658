/*
Description:
CSS variables equivalent to Bootstrap SCSS vars for quickly swapping out brand colors across components

Notes:
- Default values are placed here but can be overriden by the theme customizer
- This is not/should-not-be an exaustive list:
- add items here as needed for supporting brand colors in components that are in place
- Anything set with SCSS here instead of CSS variables needs to be manually set server-side if it needs adjusting (or in JS, for demo purposes)
*/
:root {
    // these will have to be set manually (along with --primary and --secondary) when changing brand colors
    --primary-darken-7: #{darken($primary, 7.5%)};
    --primary-darken-10: #{darken($primary, 10%)};
    --primary-darken-12: #{darken($primary, 12.5%)};
    --secondary-darken-7: #{darken($secondary, 7.5%)};
    --secondary-darken-10: #{darken($secondary, 10%)};
    --secondary-darken-12: #{darken($secondary, 12.5%)};
    --secondary-darken-20: #{darken($secondary, 20%)};
    --text-primary-yiq: #{color-yiq($primary)};
    --text-secondary-yiq: #{color-yiq($secondary)};
    --input-btn-focus-color: #{rgba($component-active-bg, 0.25)};
    // end of items that need to be overriden as brand colors change (below can optionally be overriden)
    --headings-font-family: #{$headings-font-family};
    --gray-100: #{$gray-100};
    --gray-200: #{$gray-200};
    --gray-300: #{$gray-300};
    --gray-400: #{$gray-400};
    --gray-500: #{$gray-500};
    --gray-600: #{$gray-600};
    --gray-700: #{$gray-700};
    --gray-800: #{$gray-800};
    --gray-900: #{$gray-900};
    --input-btn-focus-box-shadow: 0 0 0 #{$input-btn-focus-width} var(--input-btn-focus-color);
    --component-active-color: var(--white); // --white is defined by Bootstrap
    --component-active-bg: var(--primary); // --primary is defined by Bootstrap
    --pagination-link-color: var(--primary);
    --link-color: var(--primary);
    --link-hover-color: var(--primary-darken-12);
    --btn-focus-box-shadow: var(--input-btn-focus-box-shadow);
    --btn-disabled-opacity: #{$btn-disabled-opacity};
    --dropdown-link-active-bg: var(--component-active-bg);
}
