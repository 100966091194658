.sf-Gallery {

	h2 {
		@extend %mt-2; //.5rem;
	}
}

.sf-Gallery-thumbs-container {
	overflow: hidden;
}

.sf-Gallery-thumbs {

	a {
		display: inline-block;
		border: $gallery-thumbs-border-width solid $gallery-thumbs-border-color;
		line-height: $gallery-thumbs-line-height;
		overflow: hidden;
		cursor: pointer;

		&.is-selected {
			border-color: $gallery-thumbs--is-selected-border-color;
		}

		> img {
			vertical-align: middle;
		}
	}
}

.sf-Gallery-image {
	@extend %mt-4; //1.5rem

	position: relative;
	height: $gallery-image-height;
	text-align: center;
	background: $gallery-image-background;
	border: $gallery-image-border-width solid $gallery-image-border-color;
	line-height: $gallery-image-line-height;

	> img {
		max-height: $gallery-image-img-min-height;
		width: $gallery-image-img-width;
		display: inline-block;
	}
}

.sf-Gallery-next,
.sf-Gallery-prev {
	@extend %mt-0; //0
	@extend %mb-0; //0
	@extend %pt-0; //0
	@extend %pb-0; //0
	@extend %pl-5; //3rem
	@extend %pr-5; //3rem

	cursor: pointer;
	position: absolute;
	height: $gallery-prev-next-height;
	width: $gallery-prev-next-width;
	left: 0;
	top: 0;
	text-align: left;
	font-family: Verdana;
	color: $gallery-prev-next-color;
	font-size: $gallery-prev-next-font-size;
	line-height: $gallery-prev-next-line-height;
	text-shadow: 0 0 $gallery-prev-next-text-shadow-blur-radius $gallery-prev-next-text-shadow-color;
	opacity: 0;
	transition: opacity 0.2s ease;
	background: $gallery-prev-next-background;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;

	&:hover, 
    &:focus {
		opacity: 1;
		color: $gallery-prev-next-hover-color;
	}
}


.sf-Gallery-prev {
	background: $gallery-prev-background;
}

.sf-Gallery-next {
	background: $gallery-next-background;
	left: auto;
	right: 0;
	text-align: right;
}

.sf-Gallery-prev--simple {
    float: left;
}

.sf-Gallery-next--simple {
    float: right;
}

.sf-Gallery-navigation {
	@extend %mt-1; //.25rem
	@extend %mb-4; //1.5rem
	@extend %ml-0; //0
	@extend %mr-0; //0
}

//Video gallery
.sf-Gallery-thumbs {

	&.sf-Gallery-thumbs--video {

		> .sf-Gallery-thumb {
			@extend %mt-0; //0
			@extend %mb-2; //.5rem
			@extend %ml-1; //.25rem
			@extend %mr-1; //.25rem

			display: inline-block;
			width: $gallery-thumbs--video-width;
			height: $gallery-thumbs--video-height;
			border: $gallery-thumbs--video-border-width solid $gallery-thumbs--video-border-color;
			cursor: pointer;
		}

		a {
			@extend %m-0; //0

			display: block;
			border: $gallery-thumbs--video-anchor-border-width;
			width: $gallery-thumbs--video-anchor-width;
			height: $gallery-thumbs--video-anchor-height;
			overflow: hidden;
			cursor: pointer;
		}

		h3 {
			@extend %mt-2; //.5rem
			
			font-size: $gallery-thumbs--video-h3-font-size;
			font-weight: bold;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
    
    .sf-Gallery-thumb {
          float: left;
    }
}

//Rtl support

@if $has-rtl-support {

  @include rtl-cultures {

    .sf-Gallery-thumb {
        float: right;
    }

    .sf-Gallery-prev--simple {
        float: right;
    }

    .sf-Gallery-next--simple {
        float: left;
    }

    .mfp-counter {
        right: auto;
        left: 0;
    }
  }
}