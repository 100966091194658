﻿.homepage-used-equipment {
    background-image: url(../assets/img/bak.jpg);
    background-size: cover;

    .results-count {
        position: relative;
        background-color: $gray-100;
        border-radius: 0.25rem !important;
        box-shadow: 0px 3px 3px 0 rgba(0, 0, 0, 0.4);

        .theme-dark & {
            background-color: $gray-700;
        }
    }

    .results-count::after {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        bottom: -2em;
        left: 44%;
        border: 15px solid black;
        border-color: transparent transparent $gray-100 $gray-100;
        transform-origin: 0 0;
        transform: rotate(-45deg);
        box-shadow: -3px 3px 3px 0 rgba(0, 0, 0, 0.4);

        .theme-dark & {
            border-color: transparent transparent $gray-700 $gray-700;
        }
    }

    .price {
        font-size: 1.2rem;
    }

    .searchform {
        margin-top: -70px;
        background-color: $white;

        .theme-dark & {
            background-color: $gray-800;
        }
    }

    .featured-rounded-title {
        font-family: 'Damion';
    }

    .splide__arrows__rounded button {
        background-color: #fff;
    }
}

.theme-dark .homepage-used-equipment {
    background: unset !important;

    .card {
        background-color: $gray-800;
        box-shadow: none;
    }

    .card-footer {
        background-color: $gray-900 !important;
        border: 2px solid $gray-800;
    }

    .subtitle {
        color: #fff;
    }
}

.search-sitewide,
.used-equipment,
.used-equipment-detail {
    .search-results .border-danger {
        border-color: $gray-600 !important;
    }

    .tier-icon {
        max-width: 7rem;
        right: 0.5rem;
        top: 0.5rem;
        z-index: 2;

        @include media-breakpoint-up(md) {
            right: 1rem;
            top: 1rem;
        }
    }
}

.used-equipment-detail .tier-icon {
    margin-right: 2.5em; // Because of the splide slider

    @include media-breakpoint-up(md) {
        max-width: 10rem;
    }
}

.theme-dark .used-equipment {
    .sidebar {
        background-color: $gray-800;
    }
}
