.selection-list {
    .cursor-pointer {
        cursor: pointer;
    }

    .list {
        height: 100%;
        min-height: 130px;
    }

    table td {
        vertical-align: middle;
    }
}
