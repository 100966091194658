.nav-stacked {
	flex-direction: column;
}

@if $has-rtl-support {

  @include rtl-cultures {

    .nav-stacked>li {
        float: none;
    }
  }
}