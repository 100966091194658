﻿// $break-lg: 1200px;

// TODO: move this to utilities section
@mixin box-shadow-spread($offset-x, $offset-y, $blur-radius, $spread-radius, $color) {
    -webkit-box-shadow: $offset-x $offset-y $blur-radius $spread-radius $color;
    -moz-box-shadow: $offset-x $offset-y $blur-radius $spread-radius $color;
    box-shadow: $offset-x $offset-y $blur-radius $spread-radius $color;
}

#header,
#header-classic,
#header-rounded,
#header-wide {
    .logo img {
        max-height: 110px;
    }
}

button:focus {
    outline: none;
}

.header-wrapper {
    position: relative;
    z-index: 1030; // Above .sticky-top bootstrap utility class (1020)

    .search-container {
        min-height: 65px;
    }

    .container {
        position: relative;
    }

    button.icon {
        border: none;
        cursor: pointer;
    }

    .fa-bars {
        font-size: 35px;
        cursor: pointer;
    }

    .icon-list .icon {
        padding: 10px;
    }

    .menu-bricks {
        display: inline-flex;
    }
    // why is this in the header file?
    .text-small {
        font-size: 14px;
    }
    // TODO: a utility class should never be part of a selector
    .w-100 li {
        display: inline-flex;
        text-align: center;
    }
}

.menu-bricks {
    display: inline-block;
    padding-left: 0;
    margin: 0;

    @media (min-width: 920px) {
        display: inline-flex;
    }

    .search {
        flex: 1;
        width: calc(100% - 120px);

        .fas {
            font-size: 20px;
            margin-left: 20px;
        }

        input {
            background-color: inherit;
            border: none;
            font-size: 16px;
            width: 100%;

            :placeholder {
                font-size: 16px;
            }
        }
    }

    li {
        height: 100%;
        position: relative;
        list-style: none;
        display: inline-block;
        border-left: 1px solid;
        cursor: pointer;

        border-color: $gray-300;
        color: $gray-700;
        font-size: $font-size-xs;

        .theme-dark & {
            border-color: var(--dark);
            color: $gray-400;
        }

        a,
        input,
        .icon {
            display: block;
            padding: 0.75rem;
            font-weight: $font-weight-semi-bold;
            color: inherit;
        }

        @include media-breakpoint-down(lg) {
            a,
            input,
            .icon {
                font-size: 0.9em;
            }
        }
    }

    li:first-child {
        border-left: none;
    }

    li:hover {
        background-color: $white;

        .theme-dark & {
            background-color: $gray-700;
        }
    }

    li.no-hover:hover {
        background-color: inherit;
    }
}

.mega-menu-items {
    position: absolute;
    background-color: #fff;
    box-shadow: 0px 25px 39px -9px rgba(0, 0, 0, 0.21);
    top: 100%;
    width: 100vw;
    z-index: 2;

    .theme-dark & {
        background-color: $gray-700;
        box-shadow: none;
    }
}

// PUSH MENU
@mixin transition-timing($time) {
    -webkit-transition: all $time;
    -moz-transition: all $time;
    transition: all $time;
}

@mixin transform-translate($value) {
    -webkit-transform: translateX($value);
    -moz-transform: translateX($value);
    transform: translateX($value);
}

.mobile-pusher {
    position: fixed;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 5;
    @include transition-timing(0.5s);
    @include transform-translate(-100%);
}

.mobile-pushed {
    @include transform-translate(300px);
}

.mobile-menu {
    position: relative;
    width: 300px;
    height: 100%;
    @include transform-translate(-100%);

    ul {
        background-color: #f0f0f0;
        color: $gray-500;
        padding: 0 0 50px 0;
        list-style: none;
    }

    h2 {
        color: rgba(0, 0, 0, 0.4);
        font-size: 1.4em;
        margin: 0;
        padding: 1em 0.5em;
        text-shadow: 0 0 1px rgba(0, 0, 0, 0.1);

        a {
            padding: 1em;
            width: 100%;
        }
    }

    .main-header {
        box-shadow: inset 0 -1px rgba(0, 0, 0, 0.2);
    }

    & > .mobile-level {
        @include transform-translate(0);
    }

    ul li > a {
        color: $gray-500;
        display: block;
        padding: 0.7em 1em 0.7em 1.8em;
        box-shadow: inset 0 -1px rgba(0, 0, 0, 0.2);
        font-size: 1.4em;
        -webkit-transition: background 0.3s, box-shadow 0.3s;
        -moz-transition: background 0.3s, box-shadow 0.3s;
        transition: background 0.3s, box-shadow 0.3s;
    }
}

.mobile-cover {
    @include box-shadow-spread(9px, 0px, 45px, -8px, rgba(0, 0, 0, 0.5));
    transition: box-shadow 0.5s;

    .mobile-level.mobile-level-open {
        @include transform-translate(0);
    }
}

.mobile-level {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: #f0f0f0;
    overflow-x: auto;
    @include transform-translate(-100%);
    @include transition-timing(0.5s);

    .close-menu {
        padding-right: 15px;
        font-size: 1.25em;

        i {
            color: rgba(0, 0, 0, 0.2);
        }
    }

    .parent-page-link {
        align-items: center;
        display: flex;
        justify-content: space-between;
        text-transform: uppercase;
    }

    .top-level {
        text-transform: uppercase;
    }

    a {
        color: $gray-500;
    }

    &::after {
        z-index: -1;
    }
}

.mobile-back {
    align-items: center;
    background: rgba(0, 0, 0, 0.1);
    box-shadow: inset 0 1px rgba(0, 0, 0, 0.1);
    color: #fff;
    display: flex;
    font-size: 0.8em;
    font-weight: 700;
    justify-content: space-between;
    letter-spacing: 1px;
    padding: 1em;
    text-transform: uppercase;

    span {
        font-size: 1.5rem;
    }
}
