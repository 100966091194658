﻿.card-small-info {
    border-radius: .2rem;
    height: 100%;

    .jumbotroncardContentText {
        background-repeat: no-repeat;
        background-size: cover;
    }
}

.theme-dark .card-small-info {
    background-color: $gray-800;
    box-shadow: none;
}