.build-your-own-header {
    background-color: $gray-500;
    color: $white;
    z-index: 1;

    .list-inline-item {
        cursor: pointer;
    }
}

.build-your-own-content {
    position: relative;
    z-index: 2;

    .card {
        min-height: 10rem;
        z-index: 2;
    }

    .card-title {
        height: 2rem;
    }

    .theme-dark & {
        .card {
            background-color: $gray-100;
        }
    }
}

.loading-spinner {
    color: $gray-400;
    font-size: 10rem;
    z-index: 2;
}

@include media-breakpoint-down(lg) {
    .build-your-own.container {
        max-width: 100%;
        margin: 0;
        padding: 0;
    }
}
