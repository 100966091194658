.element-showroom {
    @include media-breakpoint-up(lg) {
        border: $border-width solid $border-color;
        border-top: none;
    }
}

.showroom-vendor-logo {
    padding: 20px 60px;
}

.showroom-navigation {
    @include media-breakpoint-up(md) {
        flex: 0 0 300px;
    }
}

.showroom-results {
    min-height: 550px; // prevents loading indicator from being chopped off
    position: relative;

    @include media-breakpoint-up(md) {
        border-left: $border-width solid $border-color;
    }
}

.showroom-results-cards {
    @include media-breakpoint-up(md) {
        // overlap card borders with showroom-results border
        margin: -1px -1px 0 -1px;

        .card-img-top {
            max-height: 224px;
            object-fit: contain;
        }
    }
}

.showroom-item-img {
    max-height: 400px;
    object-fit: contain; // keeps image from being skewed
}

.showroom-item-buttons .btn {
    @include media-breakpoint-up(md) {
        // limit button widths and middle-align within column
        max-width: 350px;
        margin-left: auto;
        margin-right: auto;
    }
}

.showroom-loading-overlay {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(#fff, 0.5);

    .fa-spinner {
        position: absolute;
        left: calc(50% - 20px);
        top: 100px;
        font-size: 40px;
        opacity: 0.75;

        @include media-breakpoint-up(sm) {
            top: 250px;
        }
    }
}

// deere description
.showroom-item-overview > div > ul {
    padding-left: 18px;

    @include media-breakpoint-up(md) {
        column-count: 2;
    }
}

.element-showroom {
    // deere specifications formatting
    #specifications {
        color: $gray-900;
        font-size: $font-size-sm;

        dl {
            border-top: 1px solid $card-border-color;
        }

        dt {
            padding: 10px 10px 0 10px;
        }

        dd {
            margin-bottom: 0;
            padding: 0 10px 10px 10px;
        }

        dt,
        dd {
            &:nth-of-type(2n) {
                background-color: $gray-200;
            }
        }

        @include media-breakpoint-up(md) {
            dl {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                border-bottom: 1px solid $card-border-color;
            }

            dt {
                width: 33%;
                padding-bottom: 10px;
                border-right: 1px solid $card-border-color;
            }

            dd {
                width: 67%;
                padding-top: 10px;
            }
        }
    }

    // deere accordions
    .expand-collapse-component-container {
        .btn-expand-all,
        .btn-collapse-all,
        .btn-show-more,
        .btn-show-less {
            display: none;
        }
    }

    .expand-collapse-component-item {
        padding-left: 10px;
        padding-right: 10px;

        &:not(:first-child) {
            border-top: 1px solid $card-border-color;
        }

        > h4 {
            margin-top: 8px;
            font-size: $h5-font-size;
            cursor: pointer;

            &:before {
                display: inline-block;
                margin-right: 10px;
                font-family: 'Font Awesome 5 Free';
                font-weight: 900;
                content: '\f067';
                color: var(--primary);
            }
        }

        &.active > h4:before {
            content: '\f068';
        }
    }

    .expand-collapse-component-item-content {
        height: 0;
        overflow: hidden;
        transition: height 0.35s ease;
    }
}
