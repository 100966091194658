﻿.custom-submit-container {
    .setup-message {
        font-size: 1.5em;
        text-align: center;
        background-color: #4e6fb4;
        color: white;
        padding: 1em;
        margin: 1em;
    }
}
