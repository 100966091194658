.email-input {
    .form-control {
        box-shadow: 3px 3px 9px -3px #615f61;
    }

    .btn {
        border-radius: 0 0.25em 0.25em 0;
        box-shadow: 3px 3px 9px -3px #615f61;
    }
}
