.splide {
    &__pagination {
        position: absolute;
        z-index: 1;
        top: calc(100% - 28px); // pulls pagination into the slider's bottom padding
        left: 50%;
        transform: translateX(-50%);
        padding: 0;

        &__page {
            display: inline-block;
            width: $splide-indicator-width;
            height: $splide-indicator-height;
            background: #ccc;
            margin: 3px;
            padding: 0;
            transition: all 0.2s linear;
            border: none;

            &.is-active {
                background: var(--primary);
            }

            &:hover {
                cursor: pointer;
                background: var(--primary-darken-7);
            }

            &:focus {
                outline: none;
            }
        }
    }
}
