﻿#footer {
    background-color: $gray-200;
    color: $gray-600;
    z-index: 1;

    a {
        color: $gray-600;
        text-decoration: none;
    }

    // make footer navigation columns equal width
    .nav-sitemap {
        table-layout: fixed;

        & > li > a {
            color: $primary;
        }
    }

    .extra-navigation {
        align-items: center;
        flex-wrap: wrap;
        display: flex;
        width: 100%;

        @media only screen and (min-width: 768px) {
            align-items: flex-end;
            width: unset;
            flex-flow: unset;
        }
    }

    .footer-logo {
        max-width: 200px;
        max-height: 40px;
    }

    .si-footer-logo {
        max-width: 150px;
    }

    .footer-search {
        .form-control {
            border-right: 0;
            max-width: 250px;
        }

        .input-group-text {
            background-color: #fff;
            border-left: 0;
        }
    }

    h1 {
        color: #242424;
    }

    .middle-section {
        display: flex;
        justify-content: center;

        @media only screen and (min-width: 768px) {
            justify-content: space-between;
        }
    }

    .subscribe-col {
        text-align: left;
        padding-right: 0;

        @media only screen and (min-width: 768px) {
            text-align: right;
        }

        .form-control {
            background-color: #f0f0f0;
            border-color: var(--primary);
            border-right: 0;
        }

        .input-group-text {
            background-color: #f0f0f0;
            border-color: var(--primary);
            color: var(--primary);
        }
    }

    .search-and-social {
        align-items: flex-end;
        display: flex;
        justify-content: flex-end;
    }

    .terms {
        font-size: 0.8rem;
        line-height: 2;
    }

    ul {
        padding-left: 0;

        li {
            list-style: none;
        }
    }
}

/*Theme*/
.theme-dark {
    #footer {
        background-color: $gray-500;
        color: $gray-800;

        .nav-sitemap > li {
            display: table-cell;
            border-left: 1px solid $gray-800;

            &:first-child {
                border-left-width: 0;
            }
        }
    }
}
