﻿body {
    // enables using CSS vars to change the primary/sitewide font family from Sitefinity
    font-family: var(--font-family-sans-serif);
}

h1,
h2,
h3,
h4,
h5,
h6 {
    // enables using CSS vars to change the headings font-family from Sitefinity
    font-family: var(--headings-font-family);
}

// resets fonts back to default (overrides Sitefinity sass rules)
h1 {
    @include font-size($h1-font-size);
}

h2 {
    @include font-size($h2-font-size);
}

h3 {
    @include font-size($h3-font-size);
}

h4 {
    @include font-size($h4-font-size);
}

h5 {
    @include font-size($h5-font-size);
    margin-bottom: 0.25rem;
}

h6 {
    @include font-size($h6-font-size);
}

.border-footer {
    border-right: 3px solid var(--primary);

    @include media-breakpoint-down(md) {
        border: 0;
    }
}

a {
    color: var(--link-color);

    &:hover {
        color: var(--link-hover-color);
        text-decoration: none;
    }
}

.underline {
    text-decoration: underline;
}

.italic-text {
    color: $gray-600;
    font-style: italic;
    font-weight: 300;
}

.subtitle {
    font-size: $font-size-base;
    color: $gray-600;
}

.text {
    color: $gray-600;
    .theme-dark & {
        color: $gray-400;
    }
}

.line-height-1 {
    line-height: 1;
}
