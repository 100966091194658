﻿@if $has-rtl-support {

    @include rtl-cultures  {
        &:not(.sfPageEditorWrp) {
            direction: rtl;

            > body {
                text-align: right;
            }
        }
    }

}