﻿.special-offers-img::before {
    background-color: white;
}

.filter-list {
    color: black !important;
    background-color: white !important;
}

.special-offers-detail {
    .splide__arrow.splide__arrow--prev{
        left: -30px;
    }
    .splide__arrow.splide__arrow--next{
        right: -30px;
    }

    .share-buttons p {
        margin-bottom: 0;
    }
}

.theme-dark {
    .subtitle {
        color: #fff;
    }

    .desktop-offer,
    .offer-header {
        background-color: $gray-800;
    }

    .mobile-offer {
        .card-header {
            background-color: $gray-900 !important;
            border: 2px solid $gray-800;
        }
    }

    .disclaimer-container {
        padding: 0;
    }

    @media screen and (max-width: 767px) {
        .offer-card-container:not(:last-child) {
            padding-bottom: 1.25rem;
        }
    }
}

@media screen and (max-width: 1023px) {
    .special-offers-img,
    .thumbnail {
        position: static;
        max-width: 100%;
    }

    #eqLink {
        display: none;
    }

    .dummy {
        margin: 5%;
    }

    .disclaimer::before,
    ::after {
        content: none;
    }
}

@media screen and (max-width: 768px) {
    .share:first-child {
        margin-top: 1rem !important;
    }

    .share-buttons a {
        width: calc(96% / 3);
    }

    .filter-list {
        width: 100%;
    }
}
