﻿.w-10 {
    width: 10% !important;
}

.mw-25 {
    max-width: 25% !important;
}

.mw-50 {
    max-width: 50% !important;
}

.mw-75 {
    max-width: 75% !important;
}

.object-fit-cover {
    object-fit: cover !important;
    object-position: center !important;
    max-height: 150px;

    @media (min-width: 576px) {
        max-height: 175px !important;
    }

    @media (min-width: 768px) {
        max-height: 200px !important;
    }

    @media (min-width: 992px) {
        max-height: 225px !important;
    }

    @media (min-width: 1200px) {
        max-height: 250px !important;
    }
}

.object-fit-contain {
    object-fit: contain !important;
    height: auto;
}

// creates responsive variations of the .h-100 utility - .h-md-100, etc.
@each $name, $value in $grid-breakpoints {
    .h-#{$name}-100 {
        @include media-breakpoint-up($name) {
            height: 100%;
        }
    }
}

// Extends Bootstrap's type display classes
$display5-size: 2.5rem;
$display6-size: 1.5rem;
$display5-weight: 300;
$display6-weight: 300;

.display-5 {
    @include font-size($display5-size);
    font-weight: $display5-weight;
    line-height: $display-line-height;
}

.display-6 {
    @include font-size($display6-size);
    font-weight: $display6-weight;
    line-height: $display-line-height;
}
