﻿.employee-info {
    a {
        color: #000;
    }

    .card-grid {
        top: -25px;
        z-index: 1;
    }
}

.theme-dark .employee-list {
    .card {
        background-color: $gray-800;
        box-shadow: none;
    }

    .employee-info a {
        color: #fff;
    }
}
