﻿.store-finder-container {
    height: 45px;
}

.store-finder-mobile-container {
    height: 44px;
}

.store-finder.mobile {
    display: inline-block;

    input,
    input::placeholder {
        font-size: 0.75rem;
    }

    .ts-icon {
        font-size: 18px;
    }
}

.store-finder {
    font-weight: 700;
    color: rgb(34, 34, 34);
    display: inline-flex;
    vertical-align: middle;

    .icon,
    input {
        height: 45px;
        font-size: 13px;
        background-color: inherit;
        color: inherit;
        border: none;
    }
    // Desktop Only
    .suggestions.desktop {
        .suggestion {
            width: 100%;
            border-radius: 0;
            padding-left: 11px;
        }
    }

    .no-gutters {
        display: inline-block;
    }

    .button-wrapper {
        position: relative;
        display: inline-flex;
        transition: width 0.5s;
        vertical-align: middle;
        height: 45px;

        button,
        a.btn {
            padding-top: 11px;
            padding-bottom: 10px;
            cursor: pointer;
            text-align: center;
        }

        .detect-button-desktop {
            color: rgb(34, 34, 34);
            padding-top: 11px;
            padding-bottom: 11px;
            height: 45px;
            width: 175px;
            float: right;
        }

        .detect-button-desktop:hover {
            background-color: #fff;
        }
    }

    .button-wrapper.grow {
        height: 45px;
        max-width: 27.3em;
    }

    .button-wrapper.shrink {
        text-align: center;
        height: 45px;
    }

    .suggestions {
        z-index: 200;
        position: absolute;
        top: 100%;

        .suggestion {
            background-color: inherit;
            border: none;
            border-top: 1px solid;
            color: inherit;
            font-size: 15px;

            :first-child {
                border-top: 0;
            }
        }
    }

    .no-hover {
        background-color: inherit;
    }

    .icon-warning {
        color: #eea236;
        font-size: 18px;
    }

    .location-input {
        width: 70%;
        outline: none;
        color: inherit;
        background-color: inherit;

        ::-webkit-input-placeholder {
            color: inherit;
            opacity: 1;
        }

        :-ms-input-placeholder {
            color: inherit;
            opacity: 1;
        }
    }

    .location-input::-ms-clear {
        display: none;
    }

    .location-input::-ms-input-placeholder {
        color: #fff;
        opacity: 1;
    }

    .location-input:moz-placeholder,
    .location-input::-moz-placeholder {
        color: #fff;
        opacity: 1;
    }

    .location-input.desktop {
        height: 29px;
        font-size: 14px;
        width: auto;
    }

    input:focus {
        border: none;
    }

    .menu-bricks {
        display: inline-flex;
    }
    // Details
    #location-details {
        top: 45px;
        width: 300px;
        position: absolute;
        min-width: 100%;
        left: 0;
        z-index: 200;
        font-size: 13px;

        .location-image {
            img {
                max-width: 276px;
                min-width: 100%;
            }

            padding-top: 0;
            margin-top: 0;
        }

        .btn,
        .btn-sm {
            border-radius: 0;
            width: 100%;
            font-weight: 700;
            text-align: center;
            cursor: pointer;
        }

        .btn {
            padding: 10px;
            font-size: 11px;
        }

        .btn-sm {
            padding: 5px;
            font-size: 10px;
            display: block;
        }
    }

    .fade-desktop-enter-active {
        display: inline-block;
    }

    .fade-desktop-leave-active {
        display: none;
    }
    // Mobile only animations
    .bar-grow,
    .bar-shrink,
    .bar-right-grow,
    .bar-right-shrink {
        position: relative;
        transition: width 0.5s;
    }

    .bar-grow,
    .bar-right-shrink {
        width: 49.9%;
    }

    .bar-shrink {
        width: 41.1%;
    }

    .bar-right-grow {
        width: 55.8%;
    }

    .drawer-enter-active,
    .drawer-leave-active {
        position: absolute;
        min-width: 100%;
        top: 0;
        left: 0;
        z-index: 200;
    }

    .drawer-enter,
    .drawer-leave-to {
        top: 0;
        left: 0;
        position: absolute;
        min-width: 100%;
        z-index: 200;
    }
}

.location-nearby {
    // make images larger for tablets where we have columns of locations but image and text are still stacked

    .store-image {
        @include media-breakpoint-up(md) {
            width: 150px;
            height: 150px;
        }

        @include media-breakpoint-up(lg) {
            width: 120px;
            height: 120px;
        }
    }

    .theme-dark & {
        .detect-button-desktop {
            background-color: #fff;
        }

        .card {
            background-color: $gray-800;
            box-shadow: none;
        }

        .card-footer .btn {
            background-color: $gray-900;
            border: 2px solid $gray-800;
            color: #fff;

            &:hover {
                background-color: $gray-900;
            }
        }

        @include media-breakpoint-down(md) {
            .card:not(:last-child) {
                margin-bottom: 1rem;
            }
        }
    }
}
