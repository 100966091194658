﻿.theme-dark {
    .modal.newsletter-modal .modal-dialog .modal-content {
        background-color: $gray-800;
        border: 2px solid $gray-300;
        border-left: none;
        box-shadow: none;

        .modal-body .close {
            color: $gray-100;
        }
    }
}

.btn-sticky-left {
    transform: rotate(90deg);
    position: fixed;
    left: -25px;
    top: 35%;
    z-index: 999;
}

.modal.modal.newsletter-modal {
    text-align: left;
}

.modal.newsletter-modal.left .modal-dialog,
.modal.newsletter-modal.right .modal-dialog {
    position: fixed;
    margin: auto;
    width: 320px;
    height: 100%;
    -webkit-transform: translate3d(0%, 0, 0);
    -ms-transform: translate3d(0%, 0, 0);
    -o-transform: translate3d(0%, 0, 0);
    transform: translate3d(0%, 0, 0);
}

.modal.newsletter-modal.left .modal-content,
.modal.newsletter-modal.right .modal-content {
    top: 20%;
    overflow-y: auto;
}

.modal.newsletter-modal.left .modal-body,
.modal.newsletter-modal.right .modal-body {
    padding: 15px;
}

.modal.newsletter-modal.left.fade.show {
    opacity: 1;
    display: block;
    width: auto;
}
/*Left*/
.modal.newsletter-modal.left.fade .modal-dialog {
    left: -320px;
    -webkit-transition: opacity 0.3s linear, left 0.6s ease-out;
    -moz-transition: opacity 0.3s linear, left 0.6s ease-out;
    -o-transition: opacity 0.3s linear, left 0.6s ease-out;
    transition: opacity 0.3s linear, left 0.6s ease-out;
}

.modal.newsletter-modal.left.fade.show .modal-dialog.show {
    left: 0;
}
/*Right*/
.modal.newsletter-modal.right.fade .modal-dialog {
    right: -320px;
    -webkit-transition: opacity 0.3s linear, right 0.6s ease-out;
    -moz-transition: opacity 0.3s linear, right 0.6s ease-out;
    -o-transition: opacity 0.3s linear, right 0.6s ease-out;
    transition: opacity 0.3s linear, right 0.6s ease-out;
}

.modal.newsletter-modal.right.fade.show .modal-dialog.show {
    right: 0;
}
/* ----- MODAL STYLE ----- */
.modal.newsletter-modal .modal-dialog .modal-content {
    border-radius: 0;
    border: none;
}

.modal.newsletter-modal .modal-dialog .modal-content .modal-body .close {
    position: absolute;
    right: 10px;
    top: 10px;
}