﻿@each $color in $element-brand-colors {
    // creates .text-secondary-yiq and .text-primary-yiq utility classes
    // use to apply white/black color to text that contrasts to the brand bg color
    .text-#{$color}-yiq {
        color: var(--text-#{$color}-yiq) !important;
    }

    // applies contrasting color to placeholder text of inputs with .text-[primary/secondary]-yiq class
    input.text-#{$color}-yiq::placeholder {
        color: var(--text-#{$color}-yiq) !important;
    }

    // adds theme support for Bootstrap .text-primary and .text-secondary color utilities
    .text-#{$color} {
        color: var(--#{$color}) !important;

        a#{&} {
            &:hover,
            &:focus {
                color: var(--#{$color}-darken-12) !important;
            }
        }
    }
}
