﻿.machine-suggester {
    svg {
        margin-right: -2px;
        z-index: -1;
    }

    .machine-suggester-container {
        @include media-breakpoint-up(md) {
            margin-left: 5rem;
        }
    }

    .bg-image {
        background-position: center;
        background-size: cover;
        filter: blur(2px) brightness(50%);
        top: 0;
        bottom: 0;
        z-index: -1;
    }

    .nav-arrow {
        align-items: center;
        background-color: #e3e3e3; // gray-300
        color: #666;
        cursor: pointer;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        height: 30px;
        width: 30px;
    }

    .brow {
        background-color: lightgray;
        padding: 0.15rem 1.25rem;

        &:not(:last-child) {
            margin-right: 0.5rem;
        }

        &.visited {
            background-color: $primary;
        }
    }

    /*******************************
     * Check marks
    ********************************/
    .check-mark-empty,
    .check-mark-filled {
        border: 1px solid #fff;
        border-radius: 50%;
    }

    .check-mark-filled {
        background-color: var(--primary);
        color: #fff;
        font-size: 0.85rem;
        padding: 0.3rem;
    }

    .check-mark-empty {
        color: transparent;
    }
    /********************************/

    .result {
        font-size: 2rem;
        line-height: 1.2;

        @include media-breakpoint-up(md) {
            font-size: 2.5rem;
        }
    }

    .request-a-quote-on-top {
        bottom: 0;
        position: fixed !important;
        top: 0;
        z-index: 3;
    }

    .suggested-implement {
        font-size: 1.1rem;
    }

    /*******************************
     * SUMMARY
    ********************************/
    .summary-button {
        right: 0;
        top: 50%;
        writing-mode: vertical-lr;
    }

    #machine-suggester-summary-modal .modal-dialog {
        margin: -1px;
    }
    /********************************/
}
