// add .modal-right-pane alongside the modal class to bring in modals from the right

.modal.modal-right-pane {
    &.fade .modal-dialog {
        width: $modal-right-pane-width !important;
        transform: translate(100vw, 0);
    }

    &.fade.show .modal-dialog {
        transform: translate(100vw - $modal-right-pane-width, 0);

        @include media-breakpoint-up(sm) {
            transform: translate(calc(100vw - #{$modal-md}), 0); // match max-width of 500px
        }
    }

    // allow modal to stretch all the way to the bottom of the page
    .modal-dialog-scrollable {
        max-height: unset;
        .modal-content {
            max-height: unset;
        }
    }
}

.theme-dark {
    .modal-content {
        background-color: $gray-800;
    }
}
