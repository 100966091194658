.splide {
    $root: &;

    &__arrow {
        position: absolute;
        z-index: 1;
        top: 50%;
        transform: translateY(-50%);
        border: none;
        padding: 0;
        background: transparent;

        svg {
            width: $splide-arrow-size;
            height: $splide-arrow-size;
            fill: var(--primary);
            transition: fill 0.2s linear;
        }

        &:hover {
            cursor: pointer;
            svg {
                fill: var(--primary-darken-7);
            }
        }

        &:focus {
            outline: none;
        }

        &--prev {
            left: 0;

            svg {
                transform: scaleX(-1);
            }
        }

        &--next {
            right: 0;
        }
    }

    &__arrows__rounded {
        font-size: 1.8rem;

        button {
            background-color: $gray-300;
            border: 0.15px solid $gray-300;
            height: 2.5rem;
            width: 2.5rem;

            &:hover {
                background-color: #e0e0e0;
            }
        }

        i {
            color: #ababab;
            padding: 0 0.75rem;
        }
    }

    &__slider {
        > #{$root}__arrows {
            #{$root}__arrow {
                &--prev {
                    left: -2.5em;
                }

                &--next {
                    right: -2.5em;
                }
            }
        }
    }
}
