// applies branding colors to vue-multiselect component

// fix height shifting as selections are made
.multiselect {
    line-height: 1;

    // vertically center placeholder text
    .multiselect__placeholder {
        padding-top: 4px;
    }

    .multiselect__spinner:after,
    .multiselect__spinner:before {
        border-color: var(--primary) transparent transparent;
    }

    // highlighted (hovered or keyboard navigated to) items
    .multiselect__option--highlight,
    .multiselect__option--highlight:after {
        background: var(--primary);
    }

    // selected item style
    .multiselect__option--selected,
    .multiselect__option--group-selected {
        background: var(--secondary);

        // selected items highlight styles (hovered, or keyboard navigated to)
        &.multiselect__option--highlight,
        &.multiselect__option--highlight:after {
            color: var(--text-secondary-yiq);
            background: var(--secondary-darken-7);
        }
    }

    // selected item tags/badges
    .multiselect__tag {
        color: var(--text-secondary-yiq);
        background: var(--secondary);
    }

    // icon on selected item tags/badges
    .multiselect__tag-icon {
        // color of the "x"
        &:after {
            color: var(--secondary-darken-20);
        }

        // color of the background surrounding the "x"
        &:focus,
        &:hover {
            background: var(--secondary-darken-7);
        }
    }
}
