﻿@mixin limit-text-lines($lineCount: 3) {
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: $lineCount;
}

//Angled Breadcrumb mixin: https://www.viget.com/articles/angled-edges-with-css-masks-and-transforms/
@mixin angle($pseudo, $flip: false, $angle: 1deg) {
    // Possible values for $pseudo are: before, after, both
    @if $pseudo == 'before' or $pseudo == 'after' or $pseudo == 'both' {
        position: relative;
        z-index: 1;
        $selector: if($pseudo == 'both', '&:before,&:after', '&:#{$pseudo}');

        #{$selector} {
            background: inherit;
            content: '';
            display: block;
            height: 50%;
            left: 0;
            position: absolute;
            right: 0;
            z-index: -1;
            -webkit-backface-visibility: hidden; // for Chrome Windows
        }

        @if $pseudo == 'before' {
            #{$selector} {
                top: 0;

                @if $flip {
                    transform: skewY($angle * -1);
                    transform-origin: 0 0;
                } @else {
                    transform: skewY($angle);
                    transform-origin: 100% 0;
                }
            }
        }

        @if $pseudo == 'after' {
            #{$selector} {
                bottom: 0;
                z-index: -1;
                @if $flip {
                    transform: skewY($angle);
                    transform-origin: 0 100%;
                } @else {
                    transform: skewY($angle * -1);
                    transform-origin: 100%;
                }
            }
        }

        @if $pseudo == 'both' {
            &:before {
                top: 0;

                @if $flip {
                    transform: skewY($angle * -1);
                    transform-origin: 0 0;
                } @else {
                    transform: skewY($angle);
                    transform-origin: 100% 0;
                }
            }

            &:after {
                bottom: 0;

                @if $flip {
                    transform: skewY($angle);
                    transform-origin: 0 0;
                } @else {
                    transform: skewY($angle * -1);
                    transform-origin: 100%;
                }
            }
        }
    }
}
