﻿// Use of REM units on non-text tags is concerning here.
// Are we wanting these items to scale as the browser font-size is adjusted?
// I think we should probbaly be using pixels for non-text margins and positions, etc.
.blog {
    .blog-featured {
        position: relative;
        bottom: 3rem;
        margin-bottom: -3rem;
    }

    .featured-img-box {
        border: 10px solid white;
        box-shadow: $box-shadow;
        padding: 0.25rem;
        width: 100%;
        z-index: 1;

        img.featured-border {
            background-color: rgba(0, 0, 0, 0.03);
            border: 0;
            box-shadow: none;
            flex-shrink: 0;
        }

        figcaption {
            background-color: var(--primary);
            bottom: 0;
            color: #fff;
            left: 0;
            opacity: 0.7;
            padding: 0.65rem;
            position: absolute;
            right: 0;

            &.featured-border {
                bottom: 0.6rem;
                border-right: 10px solid #fff;
                border-left: 10px solid #fff;
            }
        }
    }

    .featured-info-box {
        width: 100%;
    }

    .blog-subtitle {
        border-bottom: 2px solid var(--primary);
        display: inline-block;
        font-style: italic;
        font-weight: bold;
        text-transform: uppercase;
    }

    .blog-tag {
        font-size: 0.65rem;
    }

    .select {
        color: black;
        font-size: 1rem;
        font-weight: bolder;
        margin-top: 2rem;
        overflow: hidden;
        position: relative;
        width: 100%;
    }

    .select:before {
        color: var(--primary);
        content: '\25BC';
        left: 0;
        height: 100%;
        padding-right: 0.5rem;
        position: absolute;
        pointer-events: none;
        text-align: center;
        top: 0.85rem;
        width: 10%;
        z-index: 1;
    }

    .blog-select {
        // autoprefixer takes care of browser specific implementations
        appearance: none;
        background-color: white;
        border-top: none;
        border-right: none;
        border-left: none;
        border-bottom: 2px solid var(--primary);
        color: black;
        font-size: 1rem;
        font-weight: bolder;
        outline: none;
        text-align: center;
        text-align-last: center;
        width: 100%;

        option {
            /* reset to left*/
            text-align: left;
        }
        /* remove default caret for ie */
        &::-ms-expand {
            display: none;
        }
    }

    .blog-card {
        background-color: $gray-100;
        box-shadow: $box-shadow;
        height: auto;
        width: 100%;

        .theme-dark & {
            background-color: $gray-800;
        }

        .blog-card-container {
            height: 200px;

            img {
                // Fix streched image issue in IE
                flex-shrink: 0;
            }
        }
    }

    .blog-title {
        font-size: 1.75rem;
    }

    .blog-icon {
        color: var(--primary);
        margin-left: 0.25rem;
    }

    .blog-footer:last-child {
        border-radius: 0;
    }

    .blog-footer {
        border-top: none;
        background-color: rgba(0, 0, 0, 0.03);
        padding: 0.45rem 1.25rem;

        a {
            color: inherit;
            text-decoration: none;
        }
    }

    .blog-footer .blog-icon {
        font-size: 1.75rem;
    }
}

@include media-breakpoint-up(sm) {
    .blog {
        .blog-featured {
            bottom: 4rem;
            margin-bottom: -4rem;
        }

        .blog-card {
            width: 48%;
        }
    }
}

@include media-breakpoint-up(md) {
    .blog {
        .featured-info-box {
            width: 40%;
        }

        .featured-img-box {
            width: 60%;
        }

        .select {
            margin-top: 0;
            width: auto;
        }

        .select:before {
            left: 1.65rem;
        }

        .blog-card.related {
            width: 100%;
        }

        .blog-card {
            width: 31%;
        }
    }
}

@include media-breakpoint-up(lg) {
    .blog {
        .blog-featured {
            bottom: 6rem;
            margin-bottom: -6rem;
        }

        .featured-img-box {
            width: 70%;
        }

        .featured-info-box {
            width: 30%;
        }
    }
}
