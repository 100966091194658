$card-wide-default-height: 395px; // Business decision
$card-wide-max-height: 425px;

.card {
    // most of the time we want cards to go above other elements - the breadcrumbs bar for instance.
    // In cases where we don't, we can add additional styles to make that happen.
    position: relative;
    z-index: 1;
    width: 100%; // this is needed for cards that have d-inline-flex. eventually those should just add .w-100

    .btn {
        border-radius: $card-border-radius;
    }

    &.shadow {
        box-shadow: 3px 3px 15px -10px rgba(0, 0, 0, 0.4) !important;
    }

    .theme-dark & {
        border-radius: 0;
        box-shadow: none;
        background-color: $gray-800;
    }
}

.card-title {
    color: var(--primary);
}

.card-footer {
    border-top: none;
    cursor: pointer;
    font-family: $headings-font-family;
    font-weight: 700;

    a {
        color: inherit !important;
        text-decoration: none;
    }

    .theme-dark & {
        background-color: $gray-900 !important;
        border: 2px solid $gray-800;
        color: $gray-500;
    }
}

a .card-footer:hover,
a.card-footer:hover,
.card-footer.link:hover {
    background-color: rgba($black, 0.1);
}

.card-rounded {
    border-radius: 10px;

    .card-footer {
        background-color: unset;

        .btn {
            border-radius: 20px;
            padding: 0.375rem 1.5rem;
        }
    }
}

.card-standard,
.card-feature,
.card-horizontal,
.card-image-overlay {
    min-height: unset !important;
}

.card-image-overlay,
.card-standard,
.card-wide {
    overflow: hidden;
}

.card-standard {
    .ribbon-title-text {
        right: 0;
        top: 1.5rem;
    }

    .card-footer a.btn {
        font-size: 80%;
        max-width: unset;
    }

    .sf-backend-wrp & {
        .card-footer a.btn {
            font-size: 100%;
        }
    }
}

.card-feature {
    max-width: 1140px;

    .card-img-container {
        background-color: #fff;
        border: $card-border-width solid $card-border-color;
        margin: 0;
        z-index: 1;
    }

    @media (min-width: 768px) {
        .card-img-container {
            margin: -1.5rem 1rem 1rem 0;
        }
    }
}

.card-standard,
.card-feature {
    .card-text p {
        margin-bottom: 0.5rem;
    }
}

.card-horizontal {
    .card-text p {
        margin-bottom: 0;
    }
}

.card-image-overlay {
    border-radius: 10px;

    .image-overlay {
        background-position: center;
        background-size: cover;
        bottom: 0;
        filter: brightness(0.65);
        top: 0;
    }

    .card-subtitle {
        font-family: 'Damion';
        font-weight: normal;
    }

    .theme-dark & {
        .card-footer {
            background-color: unset !important;
            border: unset;
        }
    }
}

// card with text on left and image on the right
// used to make the hero's slides
.card-wide {
    box-shadow: $box-shadow;
    min-height: $card-wide-default-height;

    .card-body {
        background-color: rgba($white, 0.95);
        margin: 7% 10% 10% 10%;
        padding: 1.5rem;
        z-index: 2;
    }

    .card-title {
        font-size: 1.75rem;

        @include media-breakpoint-up(sm) {
            font-size: $h2-font-size;
        }
    }

    .card-text {
        max-width: 436px;
    }

    .btn {
        max-width: 12rem;
    }

    .card-img {
        min-height: $card-wide-default-height;
        max-height: $card-wide-max-height !important;
    }

    @include media-breakpoint-up(sm) {
        .card-body {
            align-items: stretch;
            width: 50%;
        }
    }

    @include media-breakpoint-up(lg) {
        .card-body {
            padding: 3.5rem; // ensures we have extra padding on iPads
        }
    }

    .theme-dark & {
        box-shadow: none;

        .card-body {
            background-color: $gray-800;
        }

        .card-footer {
            background-color: $gray-900;
        }
    }
}

.card-flippable {
    transition: 0.6s;
    transform-style: preserve-3d;

    &.flipped {
        transform: rotateY(180deg);
    }

    .front,
    .back {
        backface-visibility: hidden;
    }

    .front {
        z-index: 2;
    }

    .back {
        margin-left: -100%;
        transform: rotateY(180deg);
    }
}

.card-search-result-secondary {
    width: unset;

    .badge-icon {
        color: #a0a0a0;
        font-size: 1.2rem;
    }

    &.bg-1 {
        background-color: #f9f9f9;
    }

    &.bg-2 {
        background-color: #f4f4f4;
    }

    &.bg-3 {
        background-color: #eaeaea;
    }
}

.card-facebook,
.card-twitter,
.card-instagram {
    font-size: 0.7rem;

    a,
    a:active,
    a:visited {
        cursor: pointer;
    }

    img {
        width: 100%;
    }

    .card-text {
        line-height: 1.4;
    }

    .card-footer {
        font-family: inherit !important;
    }
}

.card-twitter {
    font-family: Helvetica, Roboto, 'Segoe UI', Calibri;

    a,
    a:active,
    a:visited {
        @extend .link-twitter;
    }

    a:hover {
        color: $twitter-link-hover;
    }

    .card-title {
        line-height: 1.2;

        .avatar {
            border-radius: 50%;
            max-height: 2.5em;
            max-width: 2.5em;
        }

        .screen-name {
            color: $twitter-username;
            font-size: 1em;
        }
    }

    .card-text .info {
        color: $twitter-info;
        font-size: 0.85em;

        .fa-info-circle {
            font-size: 1.2em;
        }
    }

    .card-footer {
        color: $twitter-primary;
        font-size: 0.85em;
    }

    .tw-like {
        .tw-like-shadow {
            border-radius: 50%;
            padding: 1em;
        }

        svg {
            margin: 0.4em;
            transition: stroke 0.2s ease-in-out;
            stroke: rgb(101, 119, 134);
            width: 1.25em;
        }

        &:hover {
            svg {
                stroke: rgb(224, 36, 94);
            }

            .tw-like-shadow {
                background-color: rgba(224, 36, 94, 0.1);
            }
        }
    }
}

.card-facebook {
    font-family: Helvetica;

    a,
    a:hover,
    a:active,
    a:visited {
        @extend .link-facebook;
    }

    .card-title {
        background: linear-gradient(
            0deg,
            rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 0) 0%,
            rgba(34, 34, 34, 1) 100%
        );
        line-height: 1.3;
        left: 0;
        position: absolute;
        top: 0;

        .avatar {
            border: 2px solid #fff;
            max-height: 3em;
            max-width: 3em;
        }

        .name,
        .date-created {
            color: #fff;
        }

        .date-created {
            font-size: 0.85em;
        }
    }

    .card-text .link-facebook:hover {
        text-decoration: underline;
    }

    .card-footer {
        background-color: unset;
        border-top: 0.5px solid $card-border-color;
        font-size: 0.85em;

        a {
            color: #7f7f7f;

            .fb-like {
                background-position: 0 -54px;
            }

            .fb-comment {
                background-position: 0 -18px;
            }

            .fb-share {
                background-position: 0 -90px;
            }

            &:hover {
                color: $facebook-color;

                .fb-like {
                    background-position: 0 -36px;
                }

                .fb-comment {
                    background-position: 0 0;
                }

                .fb-share {
                    background-position: 0 -72px;
                }
            }
        }
    }

    .fb-like,
    .fb-comment,
    .fb-share {
        background-image: url(../assets/SocialStream/Facebook/social_links.png);
        background-repeat: no-repeat;
        background-size: 18px 108px;
        height: 16px;
        vertical-align: bottom;
        width: 16px;
    }
}

.card-instagram {
    font-family: 'Segoe UI', Roboto, Helvetica, Arial;

    a,
    a:active,
    a:visited {
        color: $instagram-link;
    }

    .card-title {
        line-height: 1.2;

        .avatar {
            border-radius: 50%;
            max-height: 2.5em;
            max-width: 2.5em;
        }

        .name {
            color: #000;
        }

        .username {
            color: $twitter-username;
            font-size: 1em;
        }

        .cta.btn {
            font-size: 0.75em;
        }
    }

    .feedback {
        font-size: 1.8em;

        .ig-like {
            background-position: -136px -87px;

            &:hover {
                background-position: 0 -136px;
            }
        }

        .ig-comment {
            background-position: -136px -37px;

            &:hover {
                opacity: 0.5;
            }
        }

        .ig-share {
            background-position: -50px -136px;
        }

        .ig-bookmark {
            background-position: -25px -136px;

            &:hover {
                opacity: 0.5;
            }
        }
    }

    .card-text {
        .date-created {
            color: $instagram-comment-input;
        }
    }

    .card-footer {
        background-color: unset;
        color: $instagram-comment-input !important;
        font-size: 1em;
        font-weight: unset;

        &:hover {
            background-color: unset;
        }

        .add-comment {
            line-height: 2;
        }
    }

    .ig-like,
    .ig-comment,
    .ig-share,
    .ig-bookmark {
        background-image: url(../assets/SocialStream/Instagram/social_links.png);
        background-repeat: no-repeat;
        background-size: 172px 160px;
        height: 24px;
        width: 24px;
    }
}

.card-promotion {
    .card-footer {
        background-color: unset;
        padding: 0 1rem 1rem 1rem !important;
    }
}

// .card-grid class styles
// for use in conjunction with the .card-group parent class to make child cards compatible with Bootstrap .row-cols classes.

.card-grid {
    > .card {
        border-width: $card-grid-border-width !important;
    }
}

@mixin card-grid-row-col-borders($count) {
    &.card-grid {
        > .card {
            // apply border-top to only the top row
            border-top: none !important;
            &:nth-child(-n + #{$count}) {
                border-top: $card-grid-border-width solid $card-border-color !important;
            }

            // apply border-left to first column of each row
            &:nth-child(#{$count}n + 1) {
                border-left: $card-grid-border-width solid $card-border-color !important;
            }

            // removes rules applied by smaller viewport sizes
            &:not(:nth-child(#{$count}n + 1)) {
                border-left: none !important;
            }
        }
    }
}

@each $breakpoint in map-keys($grid-breakpoints) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
        @for $i from 1 through $grid-row-columns {
            .row-cols#{$infix}-#{$i} {
                @include card-grid-row-col-borders($i);
            }
        }
    }
}
