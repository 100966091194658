.location-details {
    margin: 0.25rem;
    cursor: default;

    .location-info {
        padding: 12px;
    }

    h6 {
        margin-bottom: 5px;
    }

    .row {
        padding-top: 2px;
    }
}
