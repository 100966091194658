﻿.testimonials {
    .card a {
        margin: 0 auto;
    }

    &.short-list {
        .testimonial-content {
            @include limit-text-lines($lineCount: 5);
        }
    }

    .theme-dark & {
        background-color: $gray-900;
        background-image: unset;
    }
}
