.location-list {
    .map {
        z-index: 2;
        width: 100%;
        height: 500px;
        background-color: grey;
        border: 15px solid white;
    }

    .map-all-locations {
        bottom: 1rem;
    }

    .underline {
        border-bottom: 2px solid;
        text-decoration: none;
    }

    .card-title {
        color: black;
    }

    .dealer-location-filter label {
        margin-right: 0.5rem;
    }

    .dealer-location-filter select {
        min-width: 10rem;
    }
}

.location-list-rounded {
    .map {
        height: 500px;
    }

    .card-wrapper:not(:last-child) {
        margin-bottom: 1.5rem;
    }

    .card-standard .card-body {
        background-color: $gray-200;

        .theme-dark & {
            background-color: inherit;
        }
    }
}
