﻿// Add custom branding support to Bootstrap color utilities
body {
    color: $gray-800;

    &.theme-dark {
        background-color: var(--dark) !important;
        color: #fff;
    }
}

@each $color in $element-brand-colors {
    // adds theme support for Bootstrap .bg-primary and .bg-secondary classes
    .bg-#{$color} {
        background-color: var(--#{$color}) !important;
    }

    // adds theme support for .bg-primary and .bg-secondary contextual (hover/focus) colors
    a.bg-#{$color},
    button.bg-#{$color} {
        &:hover,
        &:focus {
            background-color: var(--#{$color}-darken-7) !important;
        }
    }
}

// creates .bg-fixed utility to pair with .bg-[theme color] utility class
// use to prevent Bootstrap from applying contextual styling to buttons or links
@each $color, $value in $theme-colors {
    a.bg-#{$color}.bg-fixed,
    button.bg-#{$color}.bg-fixed {
        &:hover,
        &:focus {
            background-color: var(--#{$color}) !important;
        }
    }
}

.bg-dark,
.theme-dark {
    // allows dark theme color to be customizable
    background-color: var(--dark) !important;
}

// creates utility classes for applying grays
@each $color, $value in $grays {
    // creates .text-gray-100, etc. for applying gray text colors
    .text-gray-#{$color} {
        color: $value;
    }

    // creates .bg-gray-100, etc. for applying gray background colors
    .bg-gray-#{$color} {
        color: color-yiq($value);
        background-color: $value;
    }
}

// generates gray color classes that only apply when the dark theme is active
.theme-dark {
    @each $color, $value in $grays {
        .theme-dark__bg-gray-#{$color} {
            color: color-yiq($value) !important;
            background-color: $value !important;
        }
    }
}

// use these when you want your background to swap colors when the dark theme is activated
.bg-accent-0 {
    .theme-dark & {
        background-color: var(--dark);
    }
}

.bg-accent-1 {
    .theme-dark & {
        background-color: $gray-900;
    }
}

.bg-accent-2 {
    .theme-dark & {
        background-color: $gray-800;
    }
}

.text-facebook {
    color: $facebook-primary;
}

.link-facebook,
.link-facebook:hover,
.link-facebook:active,
.link-facebook:visited {
    color: $facebook-link;
}

.text-twitter {
    color: $twitter-primary;
}

.link-twitter {
    color: $twitter-link;

    &:active,
    &:visited {
        color: $twitter-link;
    }

    &:hover {
        color: $twitter-link-hover;
    }
}

.theme-dark {
    .form-control {
        background-color: $gray-900;
        border: 1px solid $gray-600;
        color: $gray-300;
    }
}
