﻿$hero-search-height: 725px;

.videofield .sf-Media--info {
    width: 100%;
}

// TODO: since this is in global scope should it be a utility file instead?
.arrow {
    position: absolute;
    top: 60%;
    margin-top: -50px;

    &.right {
        right: 96%;
        clip: rect(-8px, 15px, 60px, -11px);

        @media (min-width: 992px) {
            right: 97%;
        }

        @media (min-width: 1200px) {
            right: 99%;
        }
    }

    &.left {
        left: 96%;
        clip: rect(-20px, 60px, 65px, 25px);

        @media (min-width: 992px) {
            left: 97%;
        }

        @media (min-width: 1200px) {
            left: 99%;
        }
    }

    &::before {
        content: '';
        display: block;
        padding: 20px;
        box-shadow: 0 0 0 2px #292929 inset;
        cursor: pointer;
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
        transition: 0.3s;
    }

    &:hover::before {
        box-shadow: 0 0 0 4px #fff inset;
    }

    @include media-breakpoint-up(sm) {
        display: block;
    }
}

.hero,
.hero-search {
    .hero-bg {
        background-size: cover;
        background-position: center;
        top: 0;
        bottom: 0;
        -webkit-filter: opacity(0.4);
        filter: opacity(0.4);
    }

    .hero-search-bg {
        background-size: cover;
        background-position: center;
        height: $hero-search-height;
        -webkit-filter: opacity(0.4);
        filter: opacity(0.4);
        position: absolute;
    }

    .hero-search-fg {
        padding-top: 50px;
        position: relative;
        height: $hero-search-height;
    }

    .overlay {
        background-color: var(--primary);
        filter: opacity(0.6);
        opacity: 0.6;
    }

    .header {
        text-align: center;
        color: var(--white);

        h1 {
            text-transform: uppercase;
            text-shadow: 1px 1px rgba(51, 51, 51, 0.2);

            @include media-breakpoint-down(sm) {
                font-size: 1.5em;
            }
        }

        p {
            font-size: 0.95em;

            @include media-breakpoint-down(sm) {
                font-size: 0.9em;
            }
        }
    }

    .buttons {
        text-align: center;
        width: 100%;

        a {
            display: inline-block;
            border-radius: 0.5em;
            border: 1px solid var(--secondary);
            background-color: var(--primary);
            filter: opacity(0.8);
            opacity: 0.8;
            width: 13em;
            margin: 1em;
            padding: 1em;
            color: var(--white);

            @include media-breakpoint-down(sm) {
                width: 8.5em;
                margin: 0.5em;
            }

            &:hover {
                filter: opacity(1);
                opacity: 1;
            }
        }
    }
}
