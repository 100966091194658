﻿button {
    &:hover {
        cursor: pointer;
    }
}

// applies branding colors to Bootstrap .btn class
.btn {
    color: var(--text-primary-yiq);
    font-weight: $font-weight-semi-bold;

    /*&:hover,*/
    &:focus,
    &.focus {
        background-color: var(--primary-darken-7);
        box-shadow: var(--btn-focus-box-shadow);
        color: var(--text-primary-yiq) !important;
    }
}

// The code in .btn is overriding the btn-light color
.btn-light {
    color: $dark;
}

// again re-applying Bootstrap color that is removed by the specific color applied to .btn above
.btn-link {
    color: var(--primary);

    &:hover {
        color: var(--primary-darken-7);
    }
}

// button variation that increases height without changing font size like .btn-lg does
.btn-semi-lg {
    padding-top: 11px;
    padding-bottom: 11px;
}

// use alongside button style classes (.btn-primary, etc.) to create a full-width button
// that looks great with nested font-awesome icons
.btn-icon-flex {
    display: flex;
    align-items: center;
    padding: 11px 1.5rem;
    font-size: 1rem;
    font-family: $font-family-condensed;
    text-align: left;

    & + & {
        margin-top: 0.4rem;
    }
}

.theme-dark .btn-dark {
    border: 2px solid $gray-800;
}

// applies branding colors to Bootstrap .btn-primary and .btn-secondary buttons
// NOTE: this is a close approximation but not perfect replica of Bootstrap SCSS theming
@each $button in $element-brand-colors {
    .btn-#{$button} {
        background-color: var(--#{$button});
        border-color: var(--#{$button});
        color: var(--text-#{$button}-yiq);

        &:hover {
            background-color: var(--#{$button}-darken-7);
            border-color: var(--#{$button}-darken-10);
            color: var(--text-#{$button}-yiq);
        }

        &:focus,
        &.focus {
            box-shadow: var(--btn-focus-box-shadow);
        }

        &:not(:disabled):not(.disabled):active,
        &:not(:disabled):not(.disabled).active,
        .show > &.dropdown-toggle {
            background-color: var(--#{$button}-darken-10);
            border-color: var(--#{$button}-darken-12);
            color: var(--text-#{$button}-yiq);

            &:focus {
                box-shadow: var(--btn-focus-box-shadow);
            }
        }
    }
}

// applies branding colors to outline buttons
@each $button in $element-brand-colors {
    .btn-outline-#{$button} {
        color: var(--#{$button});
        border-color: var(--#{$button});

        &:hover {
            color: var(--text-#{$button}-yiq);
            background-color: var(--#{$button});
            border-color: var(--#{$button});
        }

        &:focus,
        &.focus {
            box-shadow: var(--btn-focus-box-shadow);
        }

        &:not(:disabled):not(.disabled):active,
        &:not(:disabled):not(.disabled).active,
        .show > &.dropdown-toggle {
            background-color: var(--#{$button});
            border-color: var(--#{$button});
            color: var(--text-#{$button}-yiq);

            &:focus {
                box-shadow: var(--btn-focus-box-shadow);
            }
        }
    }
}

// creates .btn-hollow-primary and .btn-hollow-secondary utility classes
@each $button in $element-brand-colors {
    .btn-hollow-#{$button} {
        @extend .btn-outline-#{$button}, .rounded-pill, .btn-sm, .px-4, .py-2;
    }
}

// creates button variant for each of our grays
@each $color, $value in $grays {
    .btn-gray-#{$color} {
        @include button-variant($value, $value);
    }
}

.share-buttons a,
.share-buttons button,
.horizontal-links {
    align-items: center;
    background-color: #fff;
    box-shadow: 3px 3px 9px -3px $gray-600;
    color: #000;
    display: inline-flex;
    flex-direction: column;
    padding: 1rem !important;

    i {
        font-size: 1.5em;
    }

    &:hover {
        background-color: var(--primary);
        color: #fff !important;
    }
}

.share-buttons a,
.share-buttons button {
    margin-right: 0.25rem;
    text-align: center;
    width: 100%;

    &:last-child {
        margin-right: 0;
    }
}

@include media-breakpoint-down(xs) {
    .share-buttons,
    .share-buttons > div {
        flex-wrap: wrap;
    }

    .share-buttons a,
    .share-buttons button {
        margin-right: 0px;
        width: 49% !important;
    }
}

@include media-breakpoint-down(sm) {
    .share-buttons a,
    .share-buttons button {
        margin-bottom: 0.5rem;
        width: 100%;
    }
}

.share-buttons a:hover,
.share-buttons button:hover {
    cursor: pointer;
}

.horizontal-links {
    min-width: 125px;
}

.starred-button {
    background-color: white;
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: 2px solid var(--primary);
    color: black;
    font-weight: bold;
    padding-bottom: 1rem;
    position: relative;
    text-decoration: none;
    width: 100%;

    i {
        color: $gray-400;
        font-size: 1.25rem;
    }

    &:hover,
    &.selected {
        color: var(--primary);

        i {
            color: var(--primary);
        }
    }

    &:hover:after,
    &.selected:after {
        color: var(--primary);
        content: '\25BC';
        font-size: 1.5rem;
        position: absolute;
        right: 45%;
        top: 85%;
    }

    // TODO: verify all this is needed.
    // It would be be better to use utility classes in specific situations where margin and widget variations are required.
    @include media-breakpoint-up(sm) {
        margin-top: 0;
        width: auto;

        &:first-child {
            margin-left: 0.75rem;
        }

        &:last-child {
            margin-right: 0.75rem;
        }
    }
}

.btn-double-border {
    border-radius: 15px;
    font-size: 1.5rem;
    padding: 2.5rem;

    &:after {
        border: 0.2rem solid #fff;
        border-radius: 10px;
        content: ' ';
        position: absolute;
        top: 0.4rem;
        left: 0.4rem;
        right: 0.4rem;
        bottom: 0.4rem;
    }
}
