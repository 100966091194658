// adds custom branding colors to Bootstrap badges
.badge-primary {
    color: var(--text-primary-yiq);
    background-color: var(--primary);
}

.badge-outline-primary {
    background-color: var(--text-primary-yiq);
    border: 1px solid var(--primary);
    color: var(--primary);
}

.badge-secondary {
    color: var(--text-secondary-yiq);
    background-color: var(--secondary);
}
