﻿$main-color: #333;
$contrast-color: #fff;

.hero-searchbar {
    /* Themes */
    &.search-wrapper-bg-transparent {
        background: none;
    }

    &.search-wrapper-bg-dark {
        background-color: rgba(0, 0, 0, 0.3);
    }

    &.search-wrapper-text-black {
        .search-icon {
            color: $main-color;
        }

        .search-bar-hero-cards .search {
            input {
                color: $main-color;
            }

            ::placeholder {
                color: $main-color;
                opacity: 1;
            }
        }

        .search form input[type=search] {
            color: $main-color;
            border-bottom: 2px solid $main-color;
        }
    }

    &.search-wrapper-text-white {
        .search-icon {
            color: $contrast-color;
        }

        .search-bar-hero-cards .search {
            .suggestions-bar {
                border-bottom: 2px solid $contrast-color;

                input {
                    color: $contrast-color;
                }
            }
        }

        .search form input[type=search] {
            color: $contrast-color;
            border-bottom: 2px solid $contrast-color;
        }
    }

    ::placeholder {
        color: $contrast-color;
        opacity: 1;
    }
}

.hero-fg .splide__list {
    margin-bottom: .25rem !important;
}
