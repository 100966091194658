.compare-container {
    min-height: 600px;
    position: relative;
    top: -15px;
    z-index: 1;

    @include media-breakpoint-up(lg) {
        top: 0;
    }

    #search-input:focus::placeholder {
        color: transparent;
    }

    .suggestions-results {
        z-index: 1;
    }

    .suggestions-result:hover {
        background-color: $gray-100;
    }

    .remove-machine-button {
        background-color: $gray-600;
        border-radius: 50%;
        color: #fff;
        cursor: pointer;
        right: 1.15rem;
        position: absolute;
        top: 1.15rem;

        &::before {
            font-size: 1.15rem;
            left: -1px;
            position: absolute;
            top: -1px;
        }
    }

    th,
    td {
        width: 33.33%;

        @include media-breakpoint-up(md) {
            width: 25%;
        }
    }

    .theme-dark & thead a {
        color: #fff;
    }

    tbody tr td {
        hyphens: auto;;
        overflow-wrap: break-word;
        word-wrap: break-word;

        &:first-child {
            background-color: #fff;
        }

        .theme-dark & {
            color: #fff;

            &:first-child {
                background-color: $gray-800;
            }
        }
    }
}
