﻿.social-stream {
    .twitter-icon {
        color: $twitter-primary;
        font-size: 1.3em;
    }

    .facebook-icon {
        color: #fff;
        font-size: 1.8em;
    }

    .instagram-icon {
        color: #000;
        font-size: 1.8em;
    }

    .social-stream-heading {
        text-align: center;

        &:before,
        &:after {
            font-size: 5px;
            vertical-align: middle;
        }

        @media screen and (max-width: 768px) {
            font-size: 1.6em;
        }
    }

    .social-stream-selector {
        .selector {
            border-top: 1px solid $card-border-color;
            border-bottom: 1px solid $card-border-color;
            color: gray;
            cursor: pointer;
            line-height: 20px;
            padding: .5rem .7rem;

            &.selector-all:hover,
            &.selector-all.active,
            &.fa-instagram:hover,
            &.fa-instagram.active {
                color: #f57a74;
            }

            &.fa-facebook:hover,
            &.fa-facebook.active {
                color: $facebook-primary;
            }

            &.fa-twitter:hover,
            &.fa-twitter.active {
                color: $twitter-primary;
            }
        }

        .selector:first-child {
            border-left: 1px solid $card-border-color;
            border-top-left-radius: 50%;
            border-bottom-left-radius: 50%;
        }

        .selector:last-child {
            border-right: 1px solid $card-border-color;
            border-top-right-radius: 50%;
            border-bottom-right-radius: 50%;
        }
    }

    .masonry {
        grid-template-columns: repeat(1, minmax(100px, 1fr));

        @media screen and (min-width: 1024px) {
            grid-template-columns: repeat(5, minmax(100px, 1fr));
        }

        @media screen and (min-width: 850px) and (max-width: 1023px) {
            grid-template-columns: repeat(4, minmax(100px, 1fr));
        }

        @media screen and (min-width: 600px) and (max-width: 849px) {
            grid-template-columns: repeat(3, minmax(100px, 1fr));
        }

        @media screen and (min-width: 425px) and (max-width: 599px) {
            grid-template-columns: repeat(2, minmax(100px, 1fr));
        }

        .masonry-item {
            filter: unset;
        }
    }
}
