.ribbon-badge {
    max-width: 100%;

    .ribbon-text {
        z-index: 1;
    }

    .ribbon-top-left-corner,
    .ribbon-top-right-corner,
    .ribbon-bottom-right-corner,
    .ribbon-bottom-left-corner {
        height: 2rem;
        transform: rotate(45deg);
        width: 2rem;
        z-index: 0;
    }

    .ribbon-top-left-corner {
        top: -1rem;
        left: 0.8rem;
    }

    .ribbon-top-right-corner {
        top: -1rem;
        right: 0.8rem;
    }

    .ribbon-bottom-right-corner {
        bottom: -1rem;
        right: 0.8rem;
    }

    .ribbon-bottom-left-corner {
        bottom: -1rem;
        left: 0.8rem;
    }
}
