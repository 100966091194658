%sf-loading-line {
	background-color: $loading-line-background;
	height: $loading-line-height;
	width: $loading-line-width;
	display: inline-block;
	-webkit-animation: stretchdelay 1.1s infinite ease-in-out;
	animation: stretchdelay 1.1s infinite ease-in-out;
}

.sf-loading {
	height: $loading-line-height;
	width: $loading-line-width;
	position: relative;
	margin: -5px 0 0 12px;

	&:before,
	&:after {
		@extend %sf-loading-line;
	}
		
	&:before,
	&:after {
		content: " ";
		position: absolute;
		top: 0;
	}
	
	&:before {
		-webkit-animation-delay: -1.2s;
		animation-delay: -1.2s;
		left: -10px;
	}
	
	&:after {
		-webkit-animation-delay: -1s;
		animation-delay: -1s;
		right: -10px;
	}
	
	> span {
		@extend %sf-loading-line;
	}
		
}

.sf-loading-wrapper {
	position: absolute;
	z-index: 902;
	background: $loading-wrapper-background;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;

	.sf-loading {
		position: absolute;
		left: 50%;
		top: 50%;
		margin: -15px 0 0 -15px;
	}
}

@-webkit-keyframes stretchdelay {
	0%, 40%, 100% {
		-webkit-transform: scaleY(0.4);
	}
	20% {
		-webkit-transform: scaleY(1);
		background-color: $webkit-keyframes-background;
	}
}


@keyframes stretchdelay {
	0%, 40%, 100% {
		transform: scaleY(0.4);
		-webkit-transform: scaleY(0.4);
	}
	20% {
		transform: scaleY(1);
		-webkit-transform: scaleY(1);
		background-color: $keyframes-background;
	}
}
