/*
Description:
Color customizations for Bootstrap pagination component.
*/
.page-link {
    color: var(--pagination-link-color);

    &:focus {
        box-shadow: var(--input-btn-focus-box-shadow);
    }

    .theme-dark & {
        color: $gray-300;
        background-color: var(--dark);
        border: $pagination-border-width solid $gray-500;

        &:hover {
            background-color: $gray-700;
        }
    }
}

.page-item {
    &.active .page-link {
        background-color: var(--component-active-bg);
        border-color: var(--component-active-bg);
        z-index: 2;
    }

    .theme-dark & {
        &.disabled .page-link {
            background-color: var(--dark);
            border-color: $gray-500;
        }
    }
}

// use to reduce pagination size on larger devices
.pagination-md-sm {
    @include media-breakpoint-up(md) {
        @include pagination-size(
            $pagination-padding-y-sm,
            $pagination-padding-x-sm,
            $font-size-sm,
            $line-height-sm,
            $border-radius-sm
        );
    }
}
