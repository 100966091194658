﻿.facebook-embed-container {

    .fb-setup {
        font-size: 1.5em;
        text-align: center;
        background-color: #4E6FB4;
        color: white;
        padding: 1em;
        margin: 1em;

    }

    .fb-page {
        iframe {
            width: 100% !important;
        }
    }

}