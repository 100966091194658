// adds support for Bootstrap 4.4 rol columns.
// https://getbootstrap.com/docs/4.4/layout/grid/#row-columns

@mixin row-cols($count) {
    & > * {
        flex: 0 0 100% / $count !important;
        max-width: 100% / $count !important;
    }
}

@each $breakpoint in map-keys($grid-breakpoints) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
        @for $i from 1 through $grid-row-columns {
            .row-cols#{$infix}-#{$i} {
                @include row-cols($i);
            }
        }
    }
}
