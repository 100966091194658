// Adds theme support for Bootstrap .list-group

.list-group {
    background-color: var(--gray-800);
}

.list-group-item {
    .theme-dark & {
        background-color: var(--gray-800);
    }

    &.active {
        color: var(--text-primary-yiq);
        background-color: var(--primary);
        border: var(--primary);
    }
}

.list-group-item-action {
    .theme-dark &:not(.active) {
        color: var(--gray-300);
        &:hover {
            background-color: var(--gray-700);
        }
    }
}
