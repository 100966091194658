﻿.hero-slider {
    .full-width-image {
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        // Business decision
        min-height: 405px;
    }

    .full-width-content {
        margin: 1.5rem 0;
    }

    @include media-breakpoint-down(sm) {
        .full-width-image {
            padding: 0;
        }

        .full-width-content {
            .hero-slider-wide-title {
                font-size: 2rem;
            }

            .hero-slider-wide-description {
                font-size: 1.2rem;
            }
        }
    }

    .hero-slider-rounded {
        .splide__pagination {
            li {
                margin: 0 0.5rem;
            }

            &__page {
                border-radius: 50%;
                height: 1rem;
                width: 1rem;

                &:not(.is-active) {
                    background-color: $gray-200;
                }
            }
        }
    }
}

@include media-breakpoint-down(sm) {
    .hero-slider-classic,
    .hero-slider-wide {
        margin: 0 2%;
    }
}
