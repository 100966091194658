.hero-rounded {
    .card-standard {
        img {
            height: 200px;
            object-fit: cover;
        }

        .card-body {
            background-color: $gray-300;

            .theme-dark & {
                background-color: unset;
            }
        }

        .card-footer {
            background-color: $gray-300;
        }
    }

    .card-wide {
        border-radius: 20px;

        .card-body {
            background-color: $gray-300;

            .theme-dark & {
                background-color: $gray-800;
            }

            a {
                border-radius: 20px;
                padding: 0.375rem 1.5rem;
            }

            @include media-breakpoint-down(md) {
                border-radius: 20px;
            }
        }
    }

    .card-wide,
    .card-image-overlay {
        border: 0;
    }
}
