﻿// input fields for range slider on search pages
.range-values {
    overflow: hidden;

    // makes input widths consistent between Chome and FF
    input[type='number'] {
        width: 110px;
    }
}

.suggestion-title > a {
    color: #000;
}

.current-form-selections .badge {
    margin-left: 2px;
    margin-right: 2px;
}

.btn-close {
    border: none;
    cursor: pointer;
    color: #fff;
    border-radius: 0.25rem;
    background-color: rgba(0, 0, 0, 0.2);
}

// styles for search form suggestions field
.autocomplete {
    position: relative;
}

.autocomplete-results {
    z-index: 2; // ensures results are positioned above other elements on page that have z-index: 1
    padding: 0;
    margin: 0;
    border: 1px solid #eee;
    height: 120px;
    overflow: auto;
    width: 100%;
    position: absolute;
    background-color: #fff;
}

.autocomplete-result {
    list-style: none;
    text-align: left;
    padding: 4px 2px;
    cursor: pointer;
}

.autocomplete-result.is-active,
.autocomplete-result:hover {
    background-color: var(--primary);
    color: white;
}

.search-status-mobile {
    box-shadow: 0 -20px 20px 15px rgba(0, 0, 0, 0.5);
}
