// Generating padding and margin silent classes
// Based on Bootstrap 4 _spacing.scss. Using Bootstrap 4 $spacers variable.
// e.g. %pl-2 %mt-3 %py-5 etc.

@each $prop, $abbrev in (margin: m, padding: p) {
    @each $size, $length in $spacers {

        %#{$abbrev}-#{$size} {
            #{$prop}: $length;
        }

        %#{$abbrev}t-#{$size},
        %#{$abbrev}y-#{$size} {
            #{$prop}-top: $length;
        }

        %#{$abbrev}r-#{$size},
        %#{$abbrev}x-#{$size} {
            #{$prop}-right: $length;
        }

        %#{$abbrev}b-#{$size},
        %#{$abbrev}y-#{$size} {
            #{$prop}-bottom: $length;
        }

        %#{$abbrev}l-#{$size},
        %#{$abbrev}x-#{$size} {
            #{$prop}-left: $length;
        }
    }
}
