﻿.build-your-own-builder {
    .theme-dark & {
        background-color: $gray-300;
        color: $dark;

        .bottom-info {
            border-color: $gray-700;
        }
    }
}

.build-your-own-builder .builder-sidebar-options {
    @include media-breakpoint-up(lg) {
        flex: 0 0 300px;
    }

    @include media-breakpoint-down(lg) {
        flex: 0 0 250px;
    }
}

.builder-sidebar .order-box {
    height: 1.4rem;
    width: 1.4rem;
    font-size: 0.8rem;
}

.builder-sidebar,
.build-your-own-builder {
    .theme-dark & {
        .card,
        .card-header {
            background-color: $gray-200;
        }
    }
    /* Cards */
    .card {
        min-height: auto;
    }

    .card-header-caret {
        transform: rotate(0);
        transition-duration: 0.5s;
        transition-property: transform;
    }
    /**/
    .card-header {
        font-size: 0.8rem;

        &:not(.collapsed) {
            color: $deere-green;
        }
    }

    .card-body-item {
        font-size: 0.8rem;
    }

    .card-body-item:hover,
    .selected-option {
        background: $gray-300;
    }

    .card-body-item {
        font-size: 0.8rem;
    }

    .card-body-item:hover,
    .selected-option {
        background: $gray-300;
    }

    .card-body-item-icon {
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        height: 1.3rem;
        width: 1.3rem;
    }

    .order-box {
        height: 1.4rem;
        width: 1.4rem;
        font-size: 0.8rem;
    }
}

.build-your-own-builder {
    .option,
    .discount {
        &:not(:last-child) {
            border-bottom: 1px solid #e3e3e3;
        }
    }

    .options-list {
        max-height: 20rem;
        overflow-y: auto;
        overflow-x: hidden;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    .machine-image-container {
        width: 21rem;
        min-height: 15rem;
        max-height: 22rem;
        position: relative;
    }

    @mixin hide-scrollbars {
        overflow-x: hidden;
        overflow-y: auto;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    .machine-overview {
        font-size: 0.8rem;
    }

    .machine-additional-options {
        @include hide-scrollbars;
        max-height: 20rem;
    }

    .image-tools {
        z-index: 2;
    }

    .detail-icon {
        font-size: 1.5em;
    }

    .price-description {
        font-size: 0.8em;
    }

    .tool-icon {
        font-size: 1.5rem;
    }

    .calculator-icon {
        font-size: 0.8em;
    }
    .price {
        font-size: 1.7em;
    }

    .financing-years {
        button {
            &:first-child {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
            &:last-child {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
        }
    }

    .monthly-payment {
        white-space: nowrap;
    }

    @include media-breakpoint-up(lg) {
        .machine-title {
            font-size: 1rem;
        }

        .price {
            font-size: 2em;
        }

        .card-header:not(.collapsed) .card-header-caret {
            transform: rotate(-180deg);
        }

        .card-header.collapsed .card-header-caret {
            transform: rotate(0);
        }

        .tool-icon {
            font-size: 1.3rem;
        }

        .builder-option {
            height: 3.7rem;
        }
    }

    @include media-breakpoint-down(md) {
        .card-header .card-header-caret,
        .card-header.collapsed .card-header-caret {
            transform: rotate(-90deg);
        }

        .price-box {
            border-bottom: 1px solid $gray-300;
        }
    }

    @include media-breakpoint-up(md) {
        .bottom-info {
            font-size: 0.8rem;
            border-top: 1px solid $gray-300;
        }
    }

    @include media-breakpoint-down(lg) {
        &.container {
            max-width: 100%;
            margin: 0;
            padding: 0;
        }

        .col-box {
            height: auto;
        }

        .price {
            font-size: 1.6em;
        }
    }

    @include media-breakpoint-up(lg) {
        .col-box {
            height: 100%;
        }
    }
}

@media print {
    .build-your-own-builder .hidden-print {
        display: none;
    }

    .build-your-own-builder .machine-overview {
        padding-right: 3rem;
        padding-left: 3rem;
    }

    .build-your-own-builder .machine-additional-options {
        max-height: 100% !important;
    }

    #header,
    #footer,
    #header-mobile {
        display: none;
    }
}
